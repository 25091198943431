import { Vector2 } from "./vector";

export class Triangle {
    constructor(
        public a: Vector2,
        public b: Vector2,
        public c: Vector2
    ) { }

    draw(graphics: CanvasRenderingContext2D, color: string) {
        graphics.fillStyle = color;

        graphics.beginPath();
        graphics.moveTo(this.a.x, this.a.y);
        graphics.lineTo(this.b.x, this.b.y);
        graphics.lineTo(this.c.x, this.c.y);
        graphics.lineTo(this.a.x, this.a.y);
        graphics.fill();
    }
}