import { Observable, map, tap } from "rxjs";
import { IntegrationState } from "./integration";
import { API_KEY_TEMPLATE } from "./integrations";
import { IIntegrationService, INTEGRATION_SERVICE_TOKEN } from "./integrations-service";
import { XenaIntegrationDto } from "./xenaIntegrationDto";
import { Inject } from "@angular/core";

export class XenaIntegrationState implements IntegrationState {
    apiKey: string = '';
    fiscalId: string = '';
    
    constructor(
        @Inject(INTEGRATION_SERVICE_TOKEN) private readonly service: IIntegrationService
    ) { }

    load(): Observable<boolean> {
        return this.service
            .xena()
            .pipe(
                map(x => { return { isActivated: x.apiKey !== '' && x.apiKey !== undefined, dto: x } }),
                tap(x => {
                    if(x.isActivated) {
                        this.apiKey = API_KEY_TEMPLATE;
                    }

                    this.fiscalId = x.dto.fiscalId;
                }),
                map(x => x.isActivated)
            );
    }

    activate(): Observable<any> {
        const dto: XenaIntegrationDto = {
            apiKey: this.apiKey,
            fiscalId: this.fiscalId
        };

        return this.service
            .updateXena(dto)
            .pipe(tap(_ => this.apiKey = API_KEY_TEMPLATE));
    }

    deactivate(): Observable<any> {
        const dto: XenaIntegrationDto = {
            apiKey: '',
            fiscalId: this.fiscalId
        };

        return this.service
            .updateXena(dto)
            .pipe(tap(_ => this.apiKey = ''));
    }
}