<ng-template #addedTemplate>
    <icon-text-button icon="ban" text="Deaktivér" color="red" (click)="toggle()"></icon-text-button>
</ng-template>

<div class="integration">
    <div class="integration-top">
        <img [src]="imageUrl" class="integration-image">
        <readonly-chip-list [categories]="categories"></readonly-chip-list>
    </div>
    <div class="integration-text">
        <span class="integration-text-title">{{title}}</span>
        <span class="integration-text-body">{{text}}</span>
    </div>
    <div class="content">
        <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!added; else addedTemplate">
        <icon-text-button icon="check" text="Aktivér" (click)="toggle()"></icon-text-button>
    </ng-container>
</div>