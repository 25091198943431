import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
    selector: 'comment-field',
    templateUrl: 'commentField.component.html',
    styleUrls: ['commentField.component.scss']
})

export class CommentFieldComponent {
    @Input()
    title: string = '';

    @Input()
    value: any = '';

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter<any>();

    onValueChanged(value: any) {
        this.value = value;
        this.valueChange.emit(value);
    }
}