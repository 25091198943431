<ng-template #expandedTemplate>
    <div class="service-box-expanded">
        <div class="service-box-expanded-topbar">
            <div>
                <fa-icon class="service-box-expanded-topbar_icon" [icon]="icon"></fa-icon>
                <span class="service-box-expanded-topbar_title">{{serviceType}}</span>
            </div>
            <infobox icon="calendar-day" title="Serviceringsdato" text="9/12-2022"></infobox>
            <div class="service-box-expanded-topbar_number">
                <span>Service nr. {{serviceNumber}}</span>
                <copy-button [text]="serviceNumber"></copy-button>
            </div>
            <span class="service-box-expanded-topbar_interval">{{interval}} interval</span>
        </div>
        <div class="service-box-expanded-progress">
            <div class="service-box-expanded-progress-text">
                <span class="service-box-expanded-progress-text_left">{{startDate}}</span>
                <span class="service-box-expanded-progress-text_right">{{endDate}}</span>
            </div>
            <div class="service-box-expanded-progress-bar">
                <div class="service-box-expanded-progress-bar-status"></div>
            </div>
        </div>
        <div class="service-box-expanded-bottom">
            <div class="service-box-expanded-bottom-left">
                <span class="service-box-expanded-bottom-left-title">Serviceret af</span>
                <employee id="007" name="Michael Gary Scott" title="Regional Manager" department="TraceTool Scranton" icon="user" [type]="EmployeeSize.Small"></employee>
                <comment-field></comment-field>
                <fa-icon class="service-box-expanded-bottom-left_expand" icon="down-left-and-up-right-to-center" (click)="toggle()"></fa-icon>
            </div>
            <div class="service-box-expanded-bottom-right">
                <attachments class="attachments"></attachments>
                <br>
                <icon-text-button class="complete-btn" icon="check" text="Fuldfør Service"></icon-text-button>
            </div>
        </div>
    </div>
</ng-template>

<ng-container *ngIf="!expanded; else expandedTemplate">
    <div class="service-box">
        <div class="service-box-topbar">
            <fa-icon class="service-box-topbar_icon" [icon]="icon"></fa-icon>
            <span class="service-box-topbar_title">{{serviceType}}</span>
            <div class="service-box-topbar_number">
                <span>Service nr. {{serviceNumber}}</span>
                <copy-button [text]="serviceNumber"></copy-button>
            </div>
            <span class="service-box-topbar_interval">{{interval}} interval</span>
        </div>
        <div class="service-box-progress">
            <div class="service-box-progress-text">
                <span class="service-box-progress-text_left">{{startDate}}</span>
                <span class="service-box-progress-text_right">{{endDate}}</span>
            </div>
            <div class="service-box-progress-bar">
                <div class="service-box-progress-bar-status"></div>
            </div>
        </div>
        <div class="service-box-bottombar">
            <fa-icon class="service-box-bottombar_expand" icon="up-right-and-down-left-from-center" (click)="toggle()" *ngIf="expandable"></fa-icon>
            <icon-text-button icon="check" text="Fuldfør Service"></icon-text-button>
            <div class="service-box-bottombar_attachments">
                <icon-button icon="image" shape="square"></icon-button>
                <icon-button icon="paperclip" shape="square"></icon-button>
                <icon-button icon="comment-dots" shape="square"></icon-button>
            </div>
        </div>
    </div>
</ng-container>