<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="EmployeeSize.Large" [ngTemplateOutlet]="large"></ng-container>
    <ng-container *ngSwitchCase="EmployeeSize.Medium" [ngTemplateOutlet]="medium"></ng-container>
    <ng-container *ngSwitchCase="EmployeeSize.Small" [ngTemplateOutlet]="small"></ng-container>
    <ng-container *ngSwitchCase="EmployeeSize.Mini" [ngTemplateOutlet]="mini"></ng-container>
</ng-container>

<ng-template #large>
    <div class="employee-large">
        <img [src]="imageUrl" class="employee-large-image">
        <div class="employee-large-text">
            <span class="employee-large-text-id">{{id}}</span>
            <span class="employee-large-text-name">{{name}}</span>
            <span class="employee-large-text-title">{{title}}</span>
            <span class="employee-large-text-department">{{department}}</span>
        </div>
        <div class="employee-large-icons">
            <fa-icon class="employee-large-icons-type" [icon]="icon"></fa-icon>
            <fa-icon 
                [style.color]="activity === Activity.Active ? 'var(--tgreen)' : 'var(--tred)'" 
                class="employee-large-icons-status" icon="circle"></fa-icon>
        </div>
    </div> 
</ng-template>

<ng-template #medium>
    <div class="employee-medium">
        <img [src]="imageUrl" class="employee-medium-image">
        <div class="employee-medium-text">
            <span class="employee-medium-text-id">{{id}}</span>
            <span class="employee-medium-text-name">{{name}}</span>
            <span class="employee-medium-text-title">{{title}}</span>
            <span class="employee-medium-text-department">{{department}}</span>
        </div>
        <div class="employee-medium-icons">
            <fa-icon
                [style.color]="activity === Activity.Active ? 'var(--tgreen)' : 'var(--tred)'" 
                class="employee-medium-icons-status" 
                icon="circle">
            </fa-icon>
        </div>
    </div> 
</ng-template>

<ng-template #small>
    <div class="employee-small">
        <img [src]="imageUrl" class="employee-small-image">
        <div class="employee-small-text">
            <span class="employee-small-text-id">Ihændehaver</span>
            <span class="employee-small-text-name">{{name}}</span>
            <span class="employee-small-text-title">{{title}}</span>
        </div>
        <div class="employee-small-icons">
            <fa-icon class="employee-small-icons-type" [icon]="icon"></fa-icon>
            <fa-icon
                [style.color]="activity === Activity.Active ? 'var(--tgreen)' : 'var(--tred)'"  
                class="employee-small-icons-status" 
                icon="circle">
            </fa-icon>
        </div>
    </div> 
</ng-template>

<ng-template #mini>
    <div class="employee-mini">
        <img [src]="imageUrl" class="employee-mini-image">
        <div class="employee-mini-text">
            <span class="employee-mini-text-id">{{id}}</span>
            <span class="employee-mini-text-name">{{name}}</span>
            <span class="employee-mini-text-title">{{title}}</span>
        </div>
    </div> 
</ng-template>