import { Component, Input, OnInit } from "@angular/core";

export class Attachment {
    constructor(
        public filename: string,
        public displayName: string,
        public url: string
    ) { }
}

export class AttachmentsPage {
    constructor(
        public items: Attachment[]
    ) { }

    tryAdd(attachment: Attachment): boolean {
        if(this.items.length >= 3)
            return false;

        this.items.push(attachment);
        return true;
    }
}

const URL: string = "https://proceedings.neurips.cc/paper_files/paper/2017/file/3f5ee243547dee91fbd053c1c4a845aa-Paper.pdf";

@Component({ 
    selector: 'attachments',
    templateUrl: 'attachments.component.html',
    styleUrls: ['attachments.component.scss']
})
export class AttachmentsComponent {
    @Input()
    title: string = '';

    private _attachments: Attachment[] = [];

    @Input()
    get attachments(): Attachment[] {
        return this._attachments;
    }

    set attachments(attachments: Attachment[]) {
        this._attachments = attachments;
        this.pages = [
            new AttachmentsPage([])
        ];

        for(var attachment of attachments) {
            this.add(attachment);
        }
    }

    pages: AttachmentsPage[]= [
        new AttachmentsPage([])
    ];

    private add(attachment: Attachment) {
        let added = false;

        for(let page of this.pages) {
            if(page.tryAdd(attachment)) {
                added = true;
                break;
            }
        }

        if(!added) {
            const page = new AttachmentsPage([attachment]);
            this.pages.push(page);
        }
    }

    onAttachmentAdded(file: File) {
        const attachment = new Attachment(file.name, file.name, URL);
        this.attachments.push(attachment);
        this.add(attachment);
    }
}