export const LOGIN_MODE_USER = 'login-mode-user';
export const LOGIN_MODE_COMPANY_ID = 'login-mode-company-id';

export class Credentials {
    constructor(
        public readonly authHeader: string
    ) { }

    static basic(
        username: string,
        password: string,
        companyId: string,
        mode: 'login-mode-user' | 'login-mode-company-id'
    ) : Credentials {
        const data: string = mode === LOGIN_MODE_USER
            ? `${username}:${password}`
            : `${companyId}\\${username}:${password}`;

        // btoa() is deprecated. The alternative is to use Buffer.from(data, 'binary').toString('base64').
        // That doesent work on my pc. If anybody can make it work, go for it. Until then this is fine.
        const base64 = btoa(data);
        return new Credentials(`Basic ${base64}`);
    }
}