<ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="EquipmentSize.Large" [ngTemplateOutlet]="large"></ng-container>
    <ng-container *ngSwitchCase="EquipmentSize.Medium" [ngTemplateOutlet]="medium"></ng-container>
    <ng-container *ngSwitchCase="EquipmentSize.Small" [ngTemplateOutlet]="small"></ng-container>
    <ng-container *ngSwitchCase="EquipmentSize.List" [ngTemplateOutlet]="list"></ng-container>
    <ng-container *ngSwitchCase="EquipmentSize.Mini" [ngTemplateOutlet]="mini"></ng-container>
    <ng-container *ngSwitchCase="EquipmentSize.Create" [ngTemplateOutlet]="create"></ng-container>
</ng-container>

<ng-template #large>
    <div class="equipment-large">
        <image-gallery class="equipment-large-image" [type]="GallerySize.Large" [images]="[thumbnail]"></image-gallery>
        <div class="equipment-large-text">
            <span class="equipment-large-text-id">{{id}}</span>
            <span class="equipment-large-text-brand">{{brand}}</span>
            <span class="equipment-large-text-info">{{info}}</span>
            <readonly-chip-list [categories]="categories"></readonly-chip-list>
        </div>
        <div class="equipment-large-icons">
            <fa-icon class="equipment-large-icons-type" icon="warehouse"></fa-icon>
            <fa-icon 
                [style.color]="isHandedOut ? 'var(--tred)' : 'var(--tgreen)'"
                class="equipment-large-icons-status" 
                icon="circle"></fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #medium>
    <div class="equipment-medium">
        <image-gallery class="equipment-medium-image" [type]="GallerySize.Medium" [images]="[thumbnail]"></image-gallery>
        <div class="equipment-medium-text">
            <span class="equipment-medium-text-id">{{id}}</span>
            <span class="equipment-medium-text-brand">{{brand}}</span>
            <span class="equipment-medium-text-info">{{info}}</span>
            <readonly-chip-list [categories]="categories"></readonly-chip-list>
        </div>
        <div class="equipment-medium-icons">
            <fa-icon 
                [style.color]="isHandedOut ? 'var(--tred)' : 'var(--tgreen)'" 
                class="equipment-medium-icons-status" 
                icon="circle"></fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #small>
    <div class="equipment-small">
        <image-gallery class="equipment-small-image" [type]="GallerySize.Small" [images]="[thumbnail]"></image-gallery>
        <div class="equipment-small-text">
            <span class="equipment-small-text-id">{{id}}</span>
            <span class="equipment-small-text-brand">{{brand}}</span>
            <span class="equipment-small-text-info">{{info}}</span>
            <readonly-chip-list [categories]="categories"></readonly-chip-list>
        </div>
        <div class="equipment-small-icons">
            <fa-icon 
                [style.color]="isHandedOut ? 'var(--tred)' : 'var(--tgreen)'" 
                class="equipment-small-icons-status" 
                icon="circle">
            </fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #list>
    <div class="equipment-list">
        <image-gallery class="equipment-list-image" [type]="GallerySize.Small" [images]="[thumbnail]"></image-gallery>
        <div class="equipment-list-text">
            <span class="equipment-list-text-id">{{id}}</span>
            <span class="equipment-list-text-brand">{{brand}}</span>
            <span class="equipment-list-text-info">{{info}}</span>
            <readonly-chip-list [categories]="categories"></readonly-chip-list>
        </div>
        <div class="equipment-list-icons">
            <fa-icon 
                [style.color]="isHandedOut ? 'var(--tred)' : 'var(--tgreen)'" 
                class="equipment-list-icons-status" 
                icon="circle">
            </fa-icon>
        </div>
    </div>
</ng-template>

<ng-template #mini>
    <div class="equipment-mini">
        <image-gallery class="equipment-mini-image" [type]="GallerySize.Mini" [images]="[thumbnail]"></image-gallery>
        <div class="equipment-mini-text">
            <span class="equipment-mini-text-brand">{{brand}}</span>
            <span class="equipment-mini-text-info">{{info}}</span>
        </div>
    </div>
</ng-template>

<ng-template #create>
    <div class="equipment-create">
        <image-gallery class="equipment-create-image" [type]="GallerySize.Mini" [images]="[thumbnail]"></image-gallery>
        <div class="equipment-create-text">
            <span class="equipment-create-text-brand">{{brand}}</span>
            <span class="equipment-create-text-info">{{info}}</span>
            <readonly-chip-list [categories]="categories"></readonly-chip-list>
        </div>
    </div>
</ng-template>