import { Component, Input } from "@angular/core";

@Component({
    selector: 'category-chip',
    templateUrl: 'category-chip.component.html',
    styleUrls: ['category-chip.component.scss']
})
export class CategoryChipComponent {
    @Input()
    name: string = '';

    @Input()
    readonly: boolean = true;
}