<div class="employee-details-topbar">
    <icon-button icon="chevron-left"></icon-button>
    <subpage-switch>
        <subpage-switch-button title="Oversigt" [selected]="true"></subpage-switch-button>
        <subpage-switch-button title="Information" (selectedChange)="toggle()"></subpage-switch-button>
    </subpage-switch>
    <icon-text-button icon="right-left" text="Overfør til mig"></icon-text-button>
</div>
<div class="employee-overview">
    <div class="employee-overview-left">
        <employee class="employee-overview-employee" id="007" name="Michael Gary Scott" title="Regional Manager" department="TraceTool Scranton" [type]="EmployeeSize.Large"></employee>

    <ng-template #infoTemplate>
        <div class="employee-overview-infoboxes-info">
            <infobox class="employee-overview-infoboxes-info-phone" icon="mobile-screen-button" title="Mobil" text="43 43 43 43" [copy]="true"></infobox>
            <infobox class="employee-overview-infoboxes-info-email" icon="envelope" title="Email" text="mscott@tracetool.io" [copy]="true"></infobox>
        </div>
        <attachments
            title="Vedhæftede Filer"
            [attachments]="[
              { url: '1', filename: 'certificate.pdf', displayName: 'Certificate' },
              { url: '2', filename: 'stuff.pdf', displayName: 'Stuff' },
              { url: '3', filename: 'dis.pdf', displayName: 'Dis' },
              { url: '4', filename: 'dat.pdf', displayName: 'Dat' },
            ]">
          </attachments>
    </ng-template>
        
    <ng-container *ngIf="!info; else infoTemplate">
        <div class="employee-overview-infoboxes">
            <infobox class="employee-overview-infoboxes-total" icon="hammer" title="Total Værktøj" text="420"></infobox>
            <infobox class="employee-overview-infoboxes-inactive" icon="ban" title="Inaktive Værktøj" text="5"></infobox>
            <infobox class="employee-overview-infoboxes-value" icon="coins" title="Materielværdi" text="57.650 DKK"></infobox>
        </div>
        <div class="employee-overview-list">
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
            <equipment id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.List"></equipment>
        </div>
    </ng-container>

    </div>
    <div class="employee-overview-right">
        <infobox-services></infobox-services>
        <log class="employee-overview-log" title="Aktivitetslog">
            <log-item icon="arrow-left" title="Indlevering" date="23/07 - 2022" text="Indleveret til TraceTool Central"></log-item>
            <log-item title="Kalibrering" date="22/07 - 2022" text="Kalibreret af Dwight Schrute"></log-item>
            <log-item icon="arrow-right" title="Udlevering" date="22/07 - 2022" text="Udleveret fra TraceTool Central"></log-item>
            <log-item icon="arrow-left" title="Indlevering" date="23/07 - 2021" text="Indleveret til TraceTool Central"></log-item>
            <log-item title="Kalibrering" date="22/07 - 2021" text="Kalibreret af Dwight Schrute"></log-item>
            <log-item icon="arrow-right" title="Udlevering" date="22/07 - 2021" text="Udleveret til Dwight Schrute"></log-item>
        </log>
    </div>
</div>