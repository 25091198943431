import { Directive, Input } from "@angular/core";

@Directive({
    selector: 'grid-item'
})

export class GridItem {
    @Input()
    subtitle: string = 'Interval';

    @Input()
    text: string = '';
}