import { NgModule } from "@angular/core";
import { AttachmentsComponent } from "./attachments.component";
import { AttachmentComponent } from "./attachment.component";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { PaginatorModule } from "src/pagination/paginator.module";
import { AttachmentAddComponent } from "./attachment-add.component";
import { NgForOf, NgIf } from "@angular/common";

@NgModule({
    declarations: [
        AttachmentsComponent,
        AttachmentComponent,
        AttachmentAddComponent
    ],
    exports: [
        AttachmentsComponent,
        AttachmentComponent,
        AttachmentAddComponent
    ],
    imports: [ 
        FontAwesomeIconsModule,
        PaginatorModule,
        NgForOf,
        NgIf
    ]
})
export class AttachmentsModule {

}