import { Directive, HostListener, Input } from "@angular/core";

@Directive({
    selector: '[dropdown-menu-trigger]'
})
export class DropdownMenuTrigger {
    private _menu!: HTMLElement;
    
    @Input()
    set menu(value: HTMLElement) {
        this._menu = value;
        value.style.display = 'none';
    }

    isOpen: boolean = false;
    isClosedGlobally: boolean = false;

    @HostListener('document:mouseup')
    onDocumentClicked() {
        if (!this.isOpen)
            return;

        this.isClosedGlobally = true;
        this.isOpen = false;

        if(this._menu)
            this._menu.style.display = 'none';
    }

    @HostListener('click')
    onClicked() {
        if (this.isClosedGlobally) {
            this.isClosedGlobally = false;
            return;
        }

        this.isClosedGlobally = false;
        this.isOpen = true;
        
        if(this._menu)
            this._menu.style.display = 'block';
    }
}