import { Component, OnInit } from "@angular/core";
import { EmployeeService } from "src/employees/employee-service";
import { EmployeeQuery } from "src/employees/employeeQuery";
import { Employee } from "src/employees/employee";
import { map } from "rxjs/operators";
import { EmployeeSorting } from "src/employees/employee-sorting";
import { DropdownOption, DropdownOptionBuilder } from "src/components/dropdown-option";
import { Activity } from "src/activity";
import { EntityType } from "src/search/searchHeader.component";

@Component({
    templateUrl: 'employee-search.component.html',
    styleUrls: ['employee-search.component.scss']
})
export class EmployeeSearchComponent implements OnInit {
    get EntityType(): typeof EntityType {
        return EntityType;
    }

    get EmployeeSorting(): typeof EmployeeSorting {
        return EmployeeSorting;
    }

    get Activity(): typeof Activity {
        return Activity;
    }

    employees: Employee[] = [];
    sorting: EmployeeSorting = EmployeeSorting.NameAscending;
    text: string = '';
    activityFilter: Activity = Activity.Active;
    sortingOptions: DropdownOption[];
    activityOptions: DropdownOption[];

    constructor(
        private readonly service: EmployeeService
    ) {
        this.sortingOptions = EmployeeSearchComponent.sortingOptions();
        this.activityOptions = EmployeeSearchComponent.activityOptions();
    }

    private static sortingOptions(): DropdownOption[] {
        const optionTemplate = new DropdownOptionBuilder().withIconColor('grey');
        return [
            optionTemplate.withIcon('arrow-down').withText('Navn').withValue(EmployeeSorting.NameAscending).build(),
            optionTemplate.withIcon('arrow-up').withText('Navn').withValue(EmployeeSorting.NameDescending).build(),
            optionTemplate.withIcon('arrow-down').withText('Oprettelsesdato').withValue(EmployeeSorting.CreationDateAscending).build(),
            optionTemplate.withIcon('arrow-up').withText('Oprettelsesdato').withValue(EmployeeSorting.CreationDateDescending).build()
        ];
    }

    private static activityOptions(): DropdownOption[] {
        const optionTemplate = new DropdownOptionBuilder().withIcon('circle');
        return [
            optionTemplate.withIconColor('green').withText('Aktiv').withValue(Activity.Active).build(),
            optionTemplate.withIconColor('red').withText('Inaktiv').withValue(Activity.Inactive).build()
        ];
    }

    ngOnInit(): void {
        this.reload();
    }

    filter(activity: Activity | null) {
        this.activityFilter = activity ?? Activity.Active;
        this.reload();
    }

    sort(sorting: EmployeeSorting) {
        this.sorting = sorting;
        this.reload();
    }

    search(text: string) {
        this.text = text;
        this.reload();
    }

    today(): number {
        return Date.now();
    }

    private reload() {
        const query = new EmployeeQuery()
            .withSorting(this.sorting)
            .withActivityFilter(this.activityFilter);

        const task = this.text.length > 0 
            ? this.service.search(this.text, query) 
            : this.service.all(query);

        task.pipe(map(x => x.map(y => Employee.from(y))))
            .subscribe(x => this.employees = x);
    }
}