import { NgModule } from '@angular/core';
import { UserService } from './user-service';
import { UserGuard } from './user-guard';
import { User } from './user';
import { UserPanelComponent } from './userPanel.component';
import { CategoriesModule } from 'src/categories/categories.module';
import { FontAwesomeIconsModule } from 'src/font-awesome-icons.module';
import { ComponentsModule } from 'src/components/components.module';

@NgModule({
    declarations: [
        UserPanelComponent
    ],
    exports: [
        UserPanelComponent
    ],
    imports: [
        CategoriesModule,
        FontAwesomeIconsModule,
        ComponentsModule
    ],
    providers: [
        UserService,
        UserGuard,
        User
    ]
})
export class UsersModule { }