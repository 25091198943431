import { Component, ContentChildren, Input, QueryList } from "@angular/core";
import { LogItem } from "./log-item.component";

@Component({
    selector: 'log',
    templateUrl: 'log.component.html',
    styleUrls: ['log.component.scss']
})

export class LogComponent {
    @ContentChildren(LogItem, { descendants: false })
    items!: QueryList<LogItem>;
      
    @Input()
    logIndex: number;

    @Input()
    title: string = '';

    constructor(){
        this.logIndex = 1;
    }

    // LOG PAGE SELECTOR

    public plusLogs(x: number): void {
        this.showLogs(this.logIndex += x);
    }

    public currentLog(x: number): void {
        this.showLogs(this.logIndex = x);
    }

    public showLogs(x: number): void {
        let logs = document.getElementsByClassName("log-container-item") as HTMLCollectionOf<HTMLElement>;
        let arrow = document.getElementsByClassName("arrow") as HTMLCollectionOf<HTMLElement>;

        if (x > logs.length) {
        this.logIndex = 1
        }
        if (x < 1) {
        this.logIndex = logs.length
        }
        
        arrow[this.logIndex-1].className += " active";
    }  
}