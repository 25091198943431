import { Directive, ElementRef, Input, Pipe, PipeTransform, Renderer2 } from "@angular/core";
import { LocaleService } from "./localeService";

@Directive({
    selector: '[localize]'
})
export class LocalizeContent {
    @Input()
    set localize(key: string) {
        let translated = this.service.localize(key);
        this.element.nativeElement.innerText = translated;
    }

    constructor(
        private readonly element: ElementRef<HTMLElement>,
        private readonly service: LocaleService
    ) { }
}

@Directive({
    selector: '[localizeProperty]'
})
export class LocalizeProperty {
    @Input()
    set localizeProperty(property: string) {
        this._localizeProperty = property;
        this.update();
    }
    
    @Input()
    set localizeKey(value: string) {
        this._localizeKey = value;
        this.update();
    }

    private _localizeProperty: string = '';
    private _localizeKey: string = '';

    constructor(
        private readonly element: ElementRef,
        private readonly renderer: Renderer2,
        private readonly service: LocaleService
    ) { }

    update(): void {
        if(this._localizeKey === '' || this._localizeProperty === '')
            return;

        let translated = this.service.localize(this._localizeKey);
        this.renderer.setAttribute(this.element.nativeElement, this._localizeProperty, translated);
    }
}

@Pipe({
    name: 'localize',
    pure: true
})
export class LocalizePipe implements PipeTransform {
    constructor(
        private readonly service: LocaleService
    ) { }
    
    transform(value: any, ...args: any[]) {
        return this.service.localize(value as string);
    }
}