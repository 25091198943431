import { Directive, Input } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

@Directive({
    selector: 'dropdown-option'
})
export class DropdownOption {
    @Input()    
    icon: IconProp = 'wrench';

    @Input()
    text: string = '';
    
    @Input()
    value: any;
    
    @Input()
    iconColor: 'red' | 'green' | 'yellow' | 'grey' = 'green';

    copy(): DropdownOption {
        const newOption = new DropdownOption();
        newOption.icon = this.icon;
        newOption.text = this.text;
        newOption.value = this.value;
        newOption.iconColor = this.iconColor;
        return newOption;
    }

    colorClass(): string {
        switch(this.iconColor) {
            case 'green':
                return 'dropdown-icon';
            case 'red':
                return 'dropdown-icon_red';
            case 'yellow':
                return 'dropdown-icon_yellow';
            case 'grey':
                return 'dropdown-icon_grey';
        }
    }
}

export class DropdownOptionBuilder {
    private readonly option: DropdownOption;

    constructor(option?: DropdownOption) {
        this.option = option ?? new DropdownOption();
    }

    withText(text: string): DropdownOptionBuilder {
        this.option.text = text;
        return this.copy();
    }

    withIcon(icon: IconProp): DropdownOptionBuilder {
        this.option.icon = icon;
        return this.copy();
    }

    withValue(value: any): DropdownOptionBuilder {
        this.option.value = value;
        return this.copy();
    }

    withIconColor(iconColor: 'red' | 'green' | 'yellow' | 'grey'): DropdownOptionBuilder {
        this.option.iconColor = iconColor;
        return this.copy();
    }

    build(): DropdownOption {
        return this.option.copy();
    }

    private copy(): DropdownOptionBuilder {
        return new DropdownOptionBuilder(this.build());
    }
}