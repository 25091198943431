import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeIconsModule } from '../font-awesome-icons.module';
import { LibraryComponent } from 'src/library/library.component';
import { AttachmentsModule } from 'src/attachments/attachments.module';
import { PaginatorModule } from 'src/pagination/paginator.module';
import { UsersModule } from 'src/users/users.module';
import { LoginComponent } from 'src/login/login.component';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BasicAuthInterceptor } from 'src/users/basic-auth-interceptor';
import { ComponentsModule } from 'src/components/components.module';
import { CategoriesModule } from 'src/categories/categories.module';
import { PRODUCT_ITEM_SERVICE, ProductItemService } from 'src/equipment/product-item-service';
import { RolesModule } from 'src/roles/roles.module';
import { ChartsModule } from 'src/components/charts/charts.module';
import { SearchModule } from 'src/search/search.module';
import { ServicesModule } from 'src/services/services.module';
import { ImagesModule } from 'src/images/images.module';
import { ButtonsModule } from 'src/components/buttons/buttons.module';
import { PHOTO_SERVICE, PhotoService } from 'src/images/photo-service';
import { IntegrationsModule } from 'src/integrations/integrations.module';
import { EmployeeSearchModule } from 'src/employees/search/employee-search.module';
import { EquipmentSearchModule } from 'src/equipment/search/equipment-search.module';
import { EquipmentModule } from 'src/equipment/equipment.module';
import { EmployeesModule } from 'src/employees/employees.module';
import { InfoBoxModule } from 'src/components/infoboxes/infoBox.module';
import { LayoutModule } from 'src/layout/layout.module';
import { EquipmentCreateModule } from 'src/equipment/create/equipment-create.module';
import { INTEGRATION_SERVICE_TOKEN as INTEGRATION_SERVICE, IntegrationService } from 'src/integrations/integrations-service';
import { ExternalPagesComponent } from './external-pages.component';
import { AppShellComponent } from './app-shell.component';
import { EquipmentDetailsModule } from 'src/equipment/details/equipment-details.module';
import { InfoBoxServicesModule } from 'src/components/infoboxes/services/infoboxServices.module';

@NgModule({
  declarations: [
    AppComponent,
    LibraryComponent,
    LoginComponent,  
    ExternalPagesComponent,
    AppShellComponent
  ],
  imports: [
    BrowserModule,
    FontAwesomeIconsModule,
    AppRoutingModule,
    AttachmentsModule,
    PaginatorModule,
    UsersModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    CategoriesModule,
    ChartsModule,
    RolesModule,
    SearchModule,
    ServicesModule,
    ImagesModule,
    ButtonsModule,
    IntegrationsModule,
    EmployeeSearchModule,
    EquipmentSearchModule,
    EquipmentCreateModule,
    EquipmentModule,
    EmployeesModule,
    InfoBoxModule,
    InfoBoxServicesModule,
    LayoutModule,
    EquipmentDetailsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: PRODUCT_ITEM_SERVICE, useClass: ProductItemService },
    { provide: PHOTO_SERVICE, useClass: PhotoService },
    { provide: INTEGRATION_SERVICE, useClass: IntegrationService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
