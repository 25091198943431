import { Injectable } from "@angular/core";
import { Settings, Theme } from "./settings";

export const THEME_KEY: string = 'settings.theme';

@Injectable({
    providedIn: 'root'
})
export class SettingsStorage {
    save(settings: Settings) {
        localStorage.setItem(THEME_KEY, settings.theme);
    }

    load(): Settings {
        const settings = new Settings();
        const theme = localStorage.getItem(THEME_KEY) ?? 'light';
        settings.theme = this.parseTheme(theme);
        return settings;
    }

    private parseTheme(value: string): Theme {
        switch(value) {
            case 'dark':
                return Theme.Dark;
                
            case 'light':
                return Theme.Light;
            
            default:
                throw new Error("Theme not implemented.");
        }
    }
}