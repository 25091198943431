import { AfterContentInit, AfterViewInit, Component, ContentChildren, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChild } from "@angular/core";
import { ChartRingPart } from "./chartRingPart";
import { Circle } from "src/geometry/circle";
import { Vector2 } from "src/geometry/vector";
import { Triangle } from "src/geometry/triangle";
import { BehaviorSubject } from "rxjs";
import { Animation } from "src/animation";
import { NumericRange } from "./range";
import { lerp } from "src/math";

export const MARGIN: number = 2;
const INDICATOR_MARGIN: number = 2;
const INDICATOR_SIZE: number = 10;

@Component({
    selector: 'chart-ring',
    templateUrl: 'chartRing.component.html',
    styleUrls: ['chartRing.component.scss']
})
export class ChartRingComponent implements AfterContentInit, AfterViewInit, OnChanges {
    @ContentChildren(ChartRingPart, { descendants: false })
    parts!: QueryList<ChartRingPart>;
    
    @ViewChild('canvas', { static: true })
    canvas!: ElementRef<HTMLCanvasElement>;
    
    @ViewChild('container', { static: true })
    container!: ElementRef<HTMLDivElement>;
    
    @ViewChild('infoBoxAnchor', { static: true })
    infoBoxAnchor!: ElementRef<HTMLDivElement>;

    @Input()
    thickness: number = 15;

    @Input()
    text: string = '';

    @Input()
    indicator?: number;

    selectedPart: BehaviorSubject<ChartRingPart | null> = new BehaviorSubject<ChartRingPart | null>(null);
    mouse: Vector2 = new Vector2(0, 0);
    offsets: number[] = [];
    animation: Animation;
    hoverIndex: number = -1;
    circle: Circle = new Circle(new Vector2(0, 0), 0);

    constructor() {
        this.animation = new Animation(0.1);
        this.animation.time.subscribe(t => this.redraw(t));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['editing'] === undefined)
            return;

        this.animation.restart();
    }
    
    ngAfterContentInit(): void {
        this.parts.changes
            .subscribe(_ => {
                this.calculateOffsets();
                this.redraw(this.animation.time.value);
            });
    }
    
    ngAfterViewInit(): void {
        this.calculateOffsets();
        this.redraw(this.animation.time.value);
    }

    onMouseLeave() {
        this.hoverIndex = -1;
        this.moveInfoBox(0, 0);
        this.update();
        this.animation.restart();
    }

    onMouseMove(event: MouseEvent): void {
        const bounds = this.canvas.nativeElement.getBoundingClientRect();
        this.mouse.x = event.clientX - bounds.left;
        this.mouse.y = event.clientY - bounds.top;
        this.moveInfoBox(event.clientX, event.clientY);
        this.update();

        const oldHoverIndex = this.hoverIndex;
        this.hoverIndex = this.findHoverIndex();

        if(this.hoverIndex !== oldHoverIndex)
            this.animation.restart();
    }

    moveInfoBox(mouseX: number, mouseY: number) {
        const containerBounds = this.container.nativeElement.getBoundingClientRect();
        const relativeY = mouseY - containerBounds.top - this.infoBoxAnchor.nativeElement.clientHeight - 4;
        this.infoBoxAnchor.nativeElement.style.top = `${relativeY}px`;

        const relativeX = mouseX - containerBounds.left - this.infoBoxAnchor.nativeElement.clientWidth * 0.5;
        const maxX = this.container.nativeElement.clientWidth - this.infoBoxAnchor.nativeElement.clientWidth * 1.5;
        const x = Math.min(relativeX, maxX);
        this.infoBoxAnchor.nativeElement.style.left = `${x}px`;
    }

    // Udregner det index som musen er over, eller returnerer -1 hvis ikke noget er fundet.
    private findHoverIndex(): number {
        const mouseToCircleCenter = this.mouse.sub(this.circle.center);

        // Mapper vinklen mellem mus og centrum af cirklen til samme range som canvas viser.
        const angle = (Math.PI * 2 + mouseToCircleCenter.angle()) % (Math.PI * 2);

        for(let i = 0; i < this.parts.length; ++i) {
            const from = this.offsets[i];
            const to = this.offsets[i + 1];

            if(angle <= to && angle >= from)
                return i;
        }

        return -1;
    }

    private calculateOffsets() {
        const totalWeight = this.totalWeight();        
        this.offsets = this.parts.map(_ => 0);

        for (let i = 0; i < this.parts.length; ++i) {
            const part = this.parts.get(i);

            if (part === undefined)
                continue;

            const t = part.weight / totalWeight;
            this.offsets[i + 1] = this.offsets[i] + t * Math.PI * 2;
        }
    }

    private update() {
        const size = this.safeDrawSize() - MARGIN;
        const halfSize = size * 0.5;
        const radius = halfSize - this.thickness * 0.5 - MARGIN;
        const center = new Vector2(
            this.canvas.nativeElement.width * 0.5 + MARGIN * 0.5,
            this.canvas.nativeElement.height * 0.5 + MARGIN * 0.5
        );

        this.circle = new Circle(center, radius);
    }

    private emitSelectedPart(): void {
        if(this.hoverIndex != -1) {
            const selected = this.parts.get(this.hoverIndex);

            if(selected !== undefined)
                this.selectedPart.next(selected);
        }
        else {
            this.selectedPart.next(null);
        }        
    }

    private redraw(animationTime: number): void {
        if(this.canvas === undefined)
            return;
        
        const graphics = this.canvas.nativeElement.getContext("2d");
       
        if(graphics === null)
            return;

        this.update();
        this.emitSelectedPart();

        this.clearCanvas(graphics);
        this.drawParts(graphics, this.hoverIndex, animationTime);
        this.drawIndicator(graphics, this.thickness, animationTime);
    }

    // Udregner størrelse der er garanteret at passe på vores canvas.
    private safeDrawSize() {
        return Math.min(
            this.canvas.nativeElement.width - 5, 
            this.canvas.nativeElement.height - 5
        );
    }
        
    private drawParts(graphics: CanvasRenderingContext2D, hoverIndex: number, animationTime: number) {
        for (let i = 0; i < this.parts.length; ++i) {
            const part = this.parts.get(i);
            
            if (part === undefined)
                continue;
            
            const hovered = i === hoverIndex;
            const from = this.offsets[i];
            const to = this.offsets[i + 1];
            const t = hovered ? animationTime : 0.0;
            part.draw(graphics, from, to, this.circle, this.thickness, t);
        }
    }

    private clearCanvas(graphics: CanvasRenderingContext2D) {
        graphics.fillStyle = "#00000000";
        graphics.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    }

    private totalWeight(): number {
        return this.parts.reduce((a, b) => a + b.weight, 0);
    }

    private drawIndicator(graphics: CanvasRenderingContext2D, thickness: number, animationTime: number): void {
        if(this.indicator === undefined)
            return;

        const totalWeight = this.totalWeight();
        const fragment = this.indicator / totalWeight;
        const offset = lerp(0, Math.PI * 2, fragment);
        const hoverRange = new NumericRange(this.offsets[this.hoverIndex], this.offsets[this.hoverIndex + 1]);
        const explodeRadius = 
            hoverRange.intersects(offset)
            ? animationTime * MARGIN * 2
            : 0;

        const innerRadius = this.circle.radius + thickness * 0.5 - MARGIN + INDICATOR_MARGIN + explodeRadius;
        const inner = this.circle.withRadius(innerRadius);
        const a = inner.pointAt(offset);

        const outerRadius = this.circle.radius + thickness * 0.5 - MARGIN + INDICATOR_MARGIN + INDICATOR_SIZE + explodeRadius;
        const outer = this.circle.withRadius(outerRadius);
        const b = outer.pointAt(offset - 0.1);
        const c = outer.pointAt(offset + 0.1);

        const triangle = new Triangle(a, b, c);
        triangle.draw(graphics, '#1E2E4299');
    }
}