import { NgModule } from "@angular/core";
import { CreateRoleComponent } from "./createRole.component";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { CreateRoleFeature } from "./create-role-feature";
import { NgForOf } from "@angular/common";

@NgModule({
    declarations: [
        CreateRoleComponent,
        CreateRoleFeature
    ],
    exports: [
        CreateRoleComponent,
        CreateRoleFeature
    ],
    imports: [
        FontAwesomeIconsModule,
        NgForOf
    ]
})
export class RolesModule { }