<section class="log">
    <span class="log-title">{{title}}</span>
    <paginator>
      <paginator-page>
        <ng-template>
          <div class="container">
            <button class="log-item" *ngFor="let item of items" (click)="item.toggle()">
              <fa-icon class="log-item-icon" [icon]="item.icon"></fa-icon>
              <span class="log-item-text">{{item.title}}</span>
              <span class="log-item-date">{{item.date}}</span>
              <fa-icon class="log-item-icon" [icon]="!item.expanded ? 'caret-left' : 'caret-down'"></fa-icon>
              <br>
              <div class="log-item-bottom">
                <p 
                  [style.display]="item.expanded ? 'block' : 'none'" 
                  class="log-item-bottom-text">
                  {{item.text}}
                </p>
            </div>
            </button>
          </div>
        </ng-template>
      </paginator-page>
    </paginator>
</section>