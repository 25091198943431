import { Component, Input } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: 'infobox',
    templateUrl: 'infoBox.component.html',
    styleUrls: ['infoBox.component.scss']
})

export class InfoBoxComponent {
    @Input()
    icon: IconProp = 'question';

    @Input()
    title: string = '';

    @Input()
    text: string = '';

    @Input()
    copy: boolean = false;

    copyText() {
        navigator.clipboard.writeText(this.text);
    }
}