import { Locale } from "./locale";

export interface Translation {
    da: string;
    en: string;
    no: string;
}

export function chooseTranslation(translation: Translation, locale: Locale): string {
    switch(locale) {
        case Locale.Da: return translation.da;
        case Locale.En: return translation.en;
        case Locale.No: return translation.no;
        default:
            throw Error("Not implemented!");
    }
}