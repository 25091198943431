import { Directive, OnInit, TemplateRef } from "@angular/core";
import { ShellComponent } from "./shell.component";

@Directive({
    selector: '[shellHeader]'
})
export class ShellHeader implements OnInit {
    constructor(
        private shell: ShellComponent,
        private host: TemplateRef<any>
    ) { }

    ngOnInit(): void {
        this.shell.header = this.host;
    }
}