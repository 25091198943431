import { NgModule } from "@angular/core";
import { ChartRingComponent } from "./chartRing.component";
import { ChartRingPart } from "./chartRingPart";
import { AsyncPipe, NgForOf } from "@angular/common";

@NgModule({
    declarations: [
        ChartRingComponent,
        ChartRingPart
    ],
    exports: [
        ChartRingComponent,
        ChartRingPart
    ],
    imports: [
        NgForOf,
        AsyncPipe
    ]
})
export class ChartsModule { }