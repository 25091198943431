import { EmployeeSorting } from "./employee-sorting";
import { Activity } from "src/activity";
import { toJavscriptDateTime, todayOffsetByDays } from "src/dates";
import { DaysBeforeConsideredInactive } from "./employee";

export class EmployeeQuery {
    public offset: number = 0;
    public count: number = 25;
    public sort: EmployeeSorting = EmployeeSorting.PublicIdAscending;
    public lastActivityLaterThan: string | null = null;
    public lastActivityEarlierThan: string | null = null;

    withOffset(offset: number): EmployeeQuery {
        this.offset = offset;
        return this;
    }

    withCount(count: number): EmployeeQuery {
        this.count = count;
        return this;
    }

    withSorting(sort: EmployeeSorting): EmployeeQuery {
        this.sort = sort;
        return this;
    }

    withActivityFilter(activity: Activity): EmployeeQuery {
        switch (activity) {
            case Activity.Active:
                this.applyActiveFilter();
                break;

            case Activity.Inactive:
                this.applyInactiveFilter();
                break;

            default:
                throw Error("Not implemented.");
                break;
        }

        return this;
    }

    private applyActiveFilter() {
        this.lastActivityEarlierThan = null;

        const filter = todayOffsetByDays(-DaysBeforeConsideredInactive);
        this.lastActivityLaterThan = toJavscriptDateTime(new Date(filter));
    }

    private applyInactiveFilter() {
        this.lastActivityLaterThan = null;

        const filter = todayOffsetByDays(-DaysBeforeConsideredInactive);
        this.lastActivityEarlierThan = toJavscriptDateTime(new Date(filter));
    }

    withLastActivityLaterThan(date: string): EmployeeQuery {
        this.lastActivityLaterThan = date;
        return this;
    }

    withLastActivityEarlierThan(date: string): EmployeeQuery {
        this.lastActivityEarlierThan = date;
        return this;
    }

    asParameters(): any {
        return {
            offset: this.offset,
            count: this.count,
            sort: this.sort,
            lastActivityLaterThan: this.lastActivityLaterThan,
            lastActivityEarlierThan: this.lastActivityEarlierThan
        };
    }
}
