<integration 
    [added]="state.isActivated"
    [text]="'keatechIntegrationDescription' | localize"
    (addedChange)="state.onToggled($event)"
    imageUrl="https://uploads-ssl.webflow.com/638f3371a0a3537eac5374de/63d12695a383ce5048ab5b42_keatech-logo%203.svg">
    <create-input
        [readonly]="state.isActivated"
        [(value)]="state.value.apiKey"
        placeholder="Api Key"
        title="Api Key"
        [required]="true">
    </create-input>
</integration>