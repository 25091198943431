<div class="role">
    <div class="role-image">
        <fa-icon [icon]="icon"></fa-icon>
        <span 
            [class]="limitReached ? 'role-image-amount role-limit-reached' : 'role-image-amount'">
            {{amount}} / {{maxAmount}}
        </span>
    </div>
    <div class="role-info">
        <span class="role-info-title">{{title}}</span>
        <span class="role-info-text">{{text}}</span>
        <ul class="role-info-features">Features
            <li *ngFor="let feature of features">{{feature.text}}</li>
        </ul>
        <span class="role-info-price">{{price}}kr per bruger</span>
    </div>
</div>