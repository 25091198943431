import { Component, Input } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: 'icon-double-text-button',
    templateUrl: 'iconDoubleTextButton.component.html',
    styleUrls: ['iconDoubleTextButton.component.scss']
})

export class IconDoubleTextButtonComponent {
    @Input()
    icon: IconProp = 'question';

    @Input()
    title: string = '';

    @Input()
    text: string = '';

    @Input()
    disabled: boolean = false;

    disableButton() {
        switch (this.disabled) {
            case true:
                return 'icon-doubleText-btn_disabled'
            case false:
                return 'icon-doubleText-btn'
        }
    }
}