<div class="container" #container>
    <canvas 
        #canvas 
        class="canvas" 
        (mousemove)="onMouseMove($event)"
        (mouseleave)="onMouseLeave()">
    </canvas>
    <p class="text">{{text}}</p>
    <div 
        #infoBoxAnchor
        class="info-box-anchor"
        [style.display]="(selectedPart | async) !== null ? 'block' : 'none'">
        <div class="info-box-background"></div>
        <div 
            class="info-box-infill"
            [style.background-color]="(selectedPart | async)?.color">
        </div>
        <p 
            class="info-box-text">
            {{(selectedPart | async)?.text}}
        </p>
    </div>
</div>