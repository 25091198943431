import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { XenaIntegrationDto } from "./xenaIntegrationDto";
import { environment } from "src/environments/environment";
import { KeatechIntegrationDto } from "./keatechIntegrationDto";

export const INTEGRATION_SERVICE_TOKEN = "INTEGRATION_SERVICE_TOKEN";

export interface IIntegrationService {
    xena(): Observable<XenaIntegrationDto>;
    updateXena(dto: XenaIntegrationDto): Observable<any>;
    keatech(): Observable<KeatechIntegrationDto>;
    updateKeatech(dto: KeatechIntegrationDto): Observable<any>;
}

@Injectable()
export class IntegrationService implements IIntegrationService {
    constructor(
        private client: HttpClient
    ) { }

    xena(): Observable<XenaIntegrationDto> {
        const url = `${environment.webApiUrl}Companies/XenaIntegrationInformation`;
        return this.client.get<XenaIntegrationDto>(url);
    }

    updateXena(dto: XenaIntegrationDto): Observable<any> {
        const url = `${environment.webApiUrl}Companies/XenaIntegrationInformation`;
        return this.client.put(url, dto);
    }

    keatech(): Observable<KeatechIntegrationDto> {
        const url = `${environment.webApiUrl}Companies/Keatech`;
        return this.client.get<KeatechIntegrationDto>(url);
    }

    updateKeatech(dto: KeatechIntegrationDto): Observable<any> {
        const url = `${environment.webApiUrl}Companies/Keatech`;
        return this.client.put(url, dto);
    }
}