import { Component } from "@angular/core";
import { Activity } from "src/activity";
import { EmployeeSize } from "src/employees/employee.component";
import { EquipmentSize } from "src/equipment/equipment-size";

@Component({
    templateUrl: './equipment-details.component.html',
    styleUrls: ['./equipment-details.component.scss']
})

export class EquipmentDetailsComponent {
    service: boolean = false;

    get EmployeeSize(): typeof EmployeeSize {
        return EmployeeSize;
    }

    get Activity(): typeof Activity {
        return Activity;
    }

    get EquipmentSize(): typeof EquipmentSize {
        return EquipmentSize;
    }

    toggle() {
        this.service = !this.service;
    }
}