import { BehaviorSubject } from "rxjs";

export class Animation {
    time: BehaviorSubject<number>;
    isRunning: boolean;
    
    constructor(
        private step: number
    ) {
        this.isRunning = false;
        this.time = new BehaviorSubject<number>(0.0);
    }

    restart(): void {
        this.time.next(0.0);
        
        if(this.isRunning) {
            return;
        }
        
        this.isRunning = true;
        requestAnimationFrame(() => this.tick());
    }

    private tick(): void {
        this.time.next(this.time.value + this.step);

        if(this.time.value < 1.0 && this.isRunning)
            requestAnimationFrame(() => this.tick());
        else
            this.isRunning = false;
    }
}