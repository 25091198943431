import { Component, ContentChildren, EventEmitter, Output, QueryList } from "@angular/core";
import { SubPageSwitchButtonComponent } from "./subpage-switch-button.component";

@Component({
    selector: 'subpage-switch',
    templateUrl: 'subpage-switch.component.html',
    styleUrls: ['subpage-switch.component.scss']
})
export class SubPageSwitchComponent {
    @ContentChildren(SubPageSwitchButtonComponent, { descendants: false })
    buttons!: QueryList<SubPageSwitchButtonComponent>;

    @Output()
    selected: EventEmitter<SubPageSwitchButtonComponent> = new EventEmitter<SubPageSwitchButtonComponent>();

    onClick(btn: SubPageSwitchButtonComponent): void {
        for(let b of this.buttons) {
            if(b !== btn)
                b.setSelected(false);
        }

        btn.setSelected(true);
        this.selected.emit(btn);
    }
}