import { Component, Input } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: 'icon-button',
    templateUrl: 'iconButton.component.html',
    styleUrls: ['iconButton.component.scss']
})

export class IconButtonComponent {
    @Input()
    icon: IconProp = 'question';

    @Input()
    color: 'blue' | 'red' = 'blue';

    @Input()
    shape: 'round' | 'square' = 'round';

    colorClass(): string {
        switch (this.color) {
            case 'blue':
                return 'icon-button' + (this.shape === 'square' ? 'blue' : '');
            case 'red':
                return 'icon-button_red' + (this.shape === 'square' ? '_red' : '');
        }
    }

    shapeClass(): string {
        switch (this.shape) {
            case 'round':
                return 'icon-button' + (this.color === 'blue' ? '' : '_red');
            case 'square':
                return 'icon-button-square' + (this.color === 'blue' ? '' : '_red');
        }
    }
}