import { Component } from "@angular/core";

@Component({
    selector: 'counter',
    templateUrl: 'counter.component.html',
    styleUrls: ['counter.component.scss']
})

export class CounterComponent {
    
}