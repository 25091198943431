import { NgModule } from "@angular/core";
import { IconButtonComponent } from "./iconButton.component";
import { IconDoubleTextButtonComponent } from "./iconDoubleTextButton.component";
import { IconTextButtonComponent } from "./iconTextButton.component";
import { TextButtonComponent } from "./textButton.component";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { CopyButtonComponent } from "./copy-button.component";
import { AsyncPipe } from "@angular/common";

@NgModule({
    declarations: [
        IconButtonComponent,
        IconDoubleTextButtonComponent,
        IconTextButtonComponent,
        TextButtonComponent,
        CopyButtonComponent
    ],
    exports: [
        IconButtonComponent,
        IconDoubleTextButtonComponent,
        IconTextButtonComponent,
        TextButtonComponent,
        CopyButtonComponent
    ],
    imports: [
        FontAwesomeIconsModule,
        AsyncPipe
    ]
})
export class ButtonsModule { }