import { Component } from "@angular/core";

@Component({
    selector: 'create-service',
    templateUrl: 'createService.component.html',
    styleUrls: ['createService.component.scss']
})

export class CreateServiceComponent {
    
}