import { Component } from "@angular/core";
import { EmployeeSize } from "./employee.component";
import { Activity } from "src/activity";
import { EquipmentSize } from "src/equipment/equipment-size";

@Component({
    templateUrl: './employee-details.component.html',
    styleUrls: ['./employee-details.component.scss']
})

export class EmployeeDetailsComponent {
    info: boolean = false;

    get EmployeeSize(): typeof EmployeeSize {
        return EmployeeSize;
    }

    get Activity(): typeof Activity {
        return Activity;
    }

    get EquipmentSize(): typeof EquipmentSize {
        return EquipmentSize;
    }

    toggle() {
        this.info = !this.info;
    }
}