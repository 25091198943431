import { NgModule } from "@angular/core";
import { EquipmentSearchComponent } from "./equipment-search.component";
import { EquipmentModule } from "../equipment.module";
import { CommonModule } from "@angular/common";
import { LayoutModule } from "src/layout/layout.module";
import { SearchModule } from "src/search/search.module";

@NgModule({
    declarations: [
        EquipmentSearchComponent
    ],
    exports: [
        EquipmentSearchComponent,
    ],
    imports: [
        EquipmentModule,
        CommonModule,
        LayoutModule,
        SearchModule
    ]
})
export class EquipmentSearchModule { }