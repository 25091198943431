import { Component, ElementRef, HostListener, Inject, Input, Output, ViewChild } from "@angular/core";
import { Category } from "./category";
import { IProductItemService, PRODUCT_ITEM_SERVICE } from "src/equipment/product-item-service";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SearchBarComponent } from "src/search/searchBar.component";

@Component({
    selector: 'category-list',
    templateUrl: 'category-list.component.html',
    styleUrls: ['category-list.component.scss']
})
export class CategoryListComponent {
    @ViewChild('dropdownContainer')
    dropdown!: ElementRef;

    @ViewChild(SearchBarComponent)
    searchBar!: SearchBarComponent;

    @Input()
    icon: IconProp = 'caret-down';

    private _categories: Category[] = [];
    
    @Input()
    @Output()
    get categories(): Category[] {
        return this._categories;
    }

    set categories(value: Category[]) {
        this.addedIds = new Set<string>(value.map(x => x.id));
        this._categories = value;
    }

    addedIds: Set<string> = new Set<string>();
    isOpen: boolean = false;
    isClosedGlobally: boolean = false;
    searchResults: Observable<Category[]> = of([]);

    constructor(
        @Inject(PRODUCT_ITEM_SERVICE) private service: IProductItemService
    ) { }

    @HostListener('document:mouseup', ['$event'])
    onClick(event: MouseEvent) {    
        if(!this.isOpen)
            return;
        
        const bounds = this.dropdown.nativeElement.getBoundingClientRect();
        const isInsideBounds = 
            event.clientX >= bounds.left 
            && event.clientX <= bounds.right 
            && event.clientY >= bounds.top
            && event.clientY <= bounds.bottom;

        if(isInsideBounds)
            return;

        this.isClosedGlobally = true;
        this.isOpen = false;
    }

    show() {
        if(this.isClosedGlobally) {
            this.isClosedGlobally = false;
            return;
        }

        this.isClosedGlobally = false;
        this.isOpen = true;
        this.searchBar.focus();
    }

    search(text: string) {
        this.searchResults = this.service
            .groups(text, 0, 25)
            .pipe(
                map(x => x.filter(y => !this.addedIds.has(y.id))),
                map(x => x.map(y => <Category>{ id: y.id, name: y.nameDa }))
            );
    }

    remove(category: Category) {
        this.addedIds.delete(category.id);
        this.categories = this.categories.filter(x => x.id !== category.id);
    }

    add(category: Category) {
        if(this.addedIds.has(category.id))
            return;

        this.categories.push(category);
        this.addedIds.add(category.id);
    }
}