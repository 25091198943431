import { NgModule } from "@angular/core";
import { InfoBoxComponent } from "./infoBox.component";
import { InfoboxAdditionalComponent } from "./infoboxAdditional.component";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "src/components/components.module";
import { ButtonsModule } from "../buttons/buttons.module";
import { EquipmentModule } from "src/equipment/equipment.module";

@NgModule({
    declarations: [
        InfoBoxComponent,
        InfoboxAdditionalComponent,
    ],
    exports: [
        InfoBoxComponent,
        InfoboxAdditionalComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeIconsModule,
        ComponentsModule,
        ButtonsModule,
    ]
})
export class InfoBoxModule { }