import { Component, Input } from "@angular/core";

@Component({
    selector: 'text-button',
    templateUrl: 'textButton.component.html',
    styleUrls: ['textButton.component.scss']
})

export class TextButtonComponent {
    @Input()
    text: string = '';

    @Input()
    disabled: boolean = false;

    disableButton() {
        switch (this.disabled) {
            case true:
                return 'text-btn_disabled'
            case false:
                return 'text-btn'
        }
    }
}