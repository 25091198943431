<section class="info-box-additional">
    <div class="info-box-additional_created">
      <span class="info-box-additional_created-title">Oprettet</span>
      <span class="info-box-additional_created-date">{{createdDate}}</span>
      <span class="info-box-additional_created-by">{{createdBy}}</span>
    </div>
    <div class="info-box-additional_edited">
      <span class="info-box-additional_edited-title">Sidst Redigeret</span>
      <span class="info-box-additional_edited-date">{{editedDate}}</span>
      <span class="info-box-additional_edited-by">{{editedBy}}</span>
    </div>
</section>