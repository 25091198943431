import { Observable, map, tap } from "rxjs";
import { IntegrationState } from "./integration";
import { API_KEY_TEMPLATE } from "./integrations";
import { IIntegrationService, INTEGRATION_SERVICE_TOKEN } from "./integrations-service";
import { KeatechIntegrationDto } from "./keatechIntegrationDto";
import { Inject } from "@angular/core";

export class KeatechIntegrationState implements IntegrationState {
    apiKey: string = '';

    constructor(
        @Inject(INTEGRATION_SERVICE_TOKEN) private readonly service: IIntegrationService
    ) { }

    load(): Observable<boolean> {
        return this.service
            .keatech()
            .pipe(
                map(x => x.apiKey !== '' && x.apiKey !== undefined),
                tap(x => {
                    if(x)
                        this.apiKey = API_KEY_TEMPLATE;
                })
            );
    }
    
    activate(): Observable<any> {
        const dto: KeatechIntegrationDto = {
            apiKey: this.apiKey
        };

        return this.service
            .updateKeatech(dto)
            .pipe(tap(_ => this.apiKey = API_KEY_TEMPLATE));
    }

    deactivate(): Observable<any> {
        const dto: KeatechIntegrationDto = {
            apiKey: ''
        };

        return this.service
            .updateKeatech(dto)
            .pipe(tap(_ => this.apiKey = ''));
    }
}