import { Component, ContentChildren, Input, QueryList } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CreateRoleFeature } from "./create-role-feature";

@Component({
    selector: 'create-role',
    templateUrl: 'createRole.component.html',
    styleUrls: ['createRole.component.scss']
})
export class CreateRoleComponent {
    @ContentChildren(CreateRoleFeature, { descendants: false })
    features!: QueryList<CreateRoleFeature>;

    @Input()
    icon: IconProp = 'user';

    @Input()
    amount: number = 0;
    
    @Input()
    maxAmount: number = 0;

    @Input()
    title: string = '';

    @Input()
    text: string = '';

    @Input()
    price: string = '';

    get limitReached(): boolean {
        return this.amount >= this.maxAmount;
    }
}