<ng-container *ngIf="type !== GallerySize.Mini; else mini;">
  <ng-container *ngTemplateOutlet="images.length > 0 ? withContent : default; context: { icon: defaultIcon }"></ng-container>
</ng-container>

<ng-template #withContent>
  <div class="image-container large">
    <img
      [style.opacity]="fadeAnimation.time | async"
      [src]="(selected | async)?.url ?? ''"
      class="image-container-item large">
      <div class="image-container-selector large" *ngIf="images.length > 1">
        <fa-icon class="arrow" icon="chevron-left" (click)="previous()"></fa-icon>
        <fa-icon 
          *ngFor="let image of images; let i = index;"
          [icon]="image.isFavorited ? 'star' : 'circle'"
          [class]="selectedIndex === i ? 'selector-icon active' : 'selector-icon'"
          (click)="select(i)">
        </fa-icon>
        <fa-icon class="arrow" icon="chevron-right" (click)="next()"></fa-icon>
      </div>
    </div>
</ng-template>

<ng-template #default let-icon="icon">
  <div class="default">
    <fa-icon class="default-icon" [icon]="icon"></fa-icon>
  </div>
</ng-template>

<ng-template #mini>
  <div class="image-container-mini">
    <img src="../assets/design/Tool1.jpg" class="image-container-mini-item">
  </div>
</ng-template>