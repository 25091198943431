import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { IntegrationComponent } from "./integration.component";
import { CategoriesModule } from "src/categories/categories.module";
import { ImagesModule } from "src/images/images.module";
import { ButtonsModule } from "../components/buttons/buttons.module";
import { ComponentsModule } from "src/components/components.module";
import { XenaIntegrationComponent } from "./xena-integration.component";
import { KeatechIntegrationComponent } from "./keatech-integration.component";
import { LayoutModule } from "src/layout/layout.module";
import { IntegrationsPageComponent } from "./integrations-page.component";
import { LocalizationModule } from "src/localization/localization.module";

@NgModule({
    declarations: [
        IntegrationComponent,
        IntegrationsPageComponent,
        XenaIntegrationComponent,
        KeatechIntegrationComponent,
    ],
    exports: [
        IntegrationComponent,
        IntegrationsPageComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeIconsModule,
        CategoriesModule,
        ImagesModule,
        ButtonsModule,
        ComponentsModule,
        LayoutModule,
        LocalizationModule
    ]
})
export class IntegrationsModule { }