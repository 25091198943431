import { Component, Input } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { EmployeeSize } from "src/employees/employee.component";

@Component({
    selector: 'service-tracker',
    templateUrl: 'serviceTracker.component.html',
    styleUrls: ['serviceTracker.component.scss']
})

export class ServiceTrackerComponent {
    expanded: boolean = false;
    
    @Input()
    expandable: boolean = false;

    @Input()
    icon: IconProp = 'wrench';

    @Input()
    serviceType: string = '';

    @Input()
    serviceNumber: string = '';

    @Input()
    interval: string = '';

    @Input()
    startDate: string = '';

    @Input()
    endDate: string = '';

    get EmployeeSize(): typeof EmployeeSize {
        return EmployeeSize;
    }

    toggle() {
        this.expanded = !this.expanded;
    }
}