import { NgModule } from "@angular/core";
import { PaginatorComponent } from "./paginator.component";
import { PaginatorPageComponent } from "./paginator-page.component";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { NgForOf, NgTemplateOutlet } from "@angular/common";
import { SubPageSwitchComponent } from "./subpage-switch.component";
import { SubPageSwitchButtonComponent } from "./subpage-switch-button.component";

@NgModule({
    declarations: [
        PaginatorComponent,
        PaginatorPageComponent,
        SubPageSwitchComponent,
        SubPageSwitchButtonComponent
    ],
    exports: [
        PaginatorComponent,
        PaginatorPageComponent,
        SubPageSwitchComponent,
        SubPageSwitchButtonComponent
    ],
    imports: [
        FontAwesomeIconsModule,
        NgTemplateOutlet,
        NgForOf
    ]
})
export class PaginatorModule { }