import { Vector2 } from "./vector";

export class Circle {
    constructor(
        public center: Vector2,
        public radius: number
    ) { }

    intersects(point: Vector2): boolean {
        const squareD = Math.pow(point.x - this.center.x, 2) + Math.pow(point.y - this.center.y, 2); //Hehe, Funny name
        const squareR = Math.pow(this.radius, 2);
        return squareD <= squareR;
    }

    pointAt(radians: number): Vector2 {
        const unit = new Vector2(
            Math.cos(radians),
            Math.sin(radians)
        );

        return unit
            .scale(this.radius)
            .add(this.center);
    }

    withRadius(radius: number): Circle {
        return new Circle(this.center, radius);
    }

    drawPart(graphics: CanvasRenderingContext2D, start: number, end: number, color: string, thickness: number) {
        graphics.lineWidth = thickness;
        graphics.strokeStyle = color;

        graphics.beginPath();
        graphics.arc(this.center.x, this.center.y, this.radius, start, end);
        graphics.stroke();
    }
}