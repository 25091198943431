import { Component, OnInit } from "@angular/core";
import { filter } from "rxjs";
import { UserAuthenticator } from "../users/user-authenticator";
import { Router } from "@angular/router";
import { Credentials, LOGIN_MODE_COMPANY_ID, LOGIN_MODE_USER } from "src/users/credentials";

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
    isCompanyLogin: boolean;
    rememberLogin: boolean;
    username: string;
    password: string;
    companyId: string;

    constructor(
        private authenticator: UserAuthenticator,
        private router: Router
    ) {
        this.username = '';
        this.password = '';
        this.companyId = '';
        this.isCompanyLogin = false;
        this.rememberLogin = false;
    }

    ngOnInit(): void {
        let navigate = () => this.navigateToHome();
        this.authenticator
            .tryLoginFromStorage()
            .pipe(filter(x => x !== null))
            .subscribe(_ => navigate());
    }

    toggleCompanyLogin(): void {
        this.isCompanyLogin = !this.isCompanyLogin;
    }

    login(): void {
        const mode = this.isCompanyLogin ? LOGIN_MODE_COMPANY_ID : LOGIN_MODE_USER;
        const credentials = Credentials.basic(this.username, this.password, this.companyId, mode);

        let navigate = () => this.navigateToHome();
        this.authenticator
            .login(credentials, this.rememberLogin)
            .subscribe(_ => navigate());
    }

    private navigateToHome() {
        this.router.navigate(['equipment']);
    }
}