import { NgModule } from "@angular/core";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { CommonModule } from "@angular/common";
import { ComponentsModule } from "src/components/components.module";
import { EquipmentModule } from "src/equipment/equipment.module";
import { InfoboxServicesComponent } from "./infoboxServices.component";
import { InfoBoxModule } from "../infoBox.module";

@NgModule({
    declarations: [
        InfoboxServicesComponent
    ],
    exports: [
        InfoboxServicesComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeIconsModule,
        ComponentsModule,
        EquipmentModule,
        InfoBoxModule,
        
    ]
})
export class InfoBoxServicesModule { }