import { Component, Input } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Activity } from "src/activity";

export enum EmployeeSize {
    Large,
    Medium,
    Small,
    Mini
}

@Component({
    selector: 'employee',
    templateUrl: 'employee.component.html',
    styleUrls: ['employee.component.scss']
})

export class EmployeeComponent {
    @Input()
    id: string = '';

    @Input()
    name: string = '';

    @Input()
    title: string = '';

    @Input()
    department: string = '';

    @Input()
    icon: IconProp = 'user';

    @Input()
    imageUrl: string = '../assets/design/MichaelScott.png';

    @Input()
    type: EmployeeSize = EmployeeSize.Medium;

    @Input()
    activity: Activity = Activity.Active;

    get EmployeeSize(): typeof EmployeeSize {
        return EmployeeSize;
    }

    get Activity(): typeof Activity {
        return Activity;
    }
}