import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map, of } from "rxjs";
import { EmployeeDto } from "./employeeDto";
import { PagedQueryResultDto } from "src/pagedQueryResultDto";
import { environment } from "src/environments/environment";
import { EmployeeQuery } from "./employeeQuery";

@Injectable({
    providedIn: 'root'
})
export class EmployeeService {
    constructor(
        private client: HttpClient
    ) { }

    search(text: string, query: EmployeeQuery): Observable<EmployeeDto[]> {
        const url = `${environment.webApiUrl}Employees/Search`;
        const parameters = query.asParameters();
        parameters.text = text;

        return this.client
            .get<PagedQueryResultDto<EmployeeDto>>(url, { params: parameters })
            .pipe(map(x => x.items));
    }

    all(query: EmployeeQuery): Observable<EmployeeDto[]> {
        const url = `${environment.webApiUrl}Employees`;
        const parameters = query.asParameters();

        return this.client
            .get<PagedQueryResultDto<EmployeeDto>>(url, { params: parameters })
            .pipe(map(x => x.items));
    }
}