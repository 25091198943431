import { NgModule } from "@angular/core";
import { SearchBarComponent } from "./searchBar.component";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { SearchHeaderComponent } from "./searchHeader.component";
import { ComponentsModule } from "src/components/components.module";
import { NgForOf } from "@angular/common";

@NgModule({
    declarations: [
        SearchBarComponent,
        SearchHeaderComponent
    ],
    exports: [
        SearchBarComponent,
        SearchHeaderComponent
    ],
    imports: [
        FontAwesomeIconsModule,
        ComponentsModule,
        NgForOf
    ]
})
export class SearchModule { }