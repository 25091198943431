import { NgModule } from "@angular/core";
import { ShellComponent } from "./shell.component";
import { ComponentsModule } from "src/components/components.module";
import { UsersModule } from "src/users/users.module";
import { SearchModule } from "src/search/search.module";
import { ButtonsModule } from "src/components/buttons/buttons.module";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { EquipmentModule } from "src/equipment/equipment.module";
import { CommonModule } from "@angular/common";
import { ShellHeader } from "./shellHeader";

@NgModule({
    declarations: [
        ShellComponent,
        ShellHeader,
    ],
    exports: [
        ShellComponent,
        ShellHeader,
    ],
    imports: [
        ComponentsModule,
        UsersModule,
        SearchModule,
        ButtonsModule,
        FontAwesomeIconsModule,
        EquipmentModule,
        CommonModule
    ]
})
export class LayoutModule { }