import { Observable, Subject } from "rxjs";

export class Image {
    constructor(
        public id: string,
        public url: string,
        public isFavorited: boolean
    ) { }
}

export function rotateImage(url: string): Observable<string> {
    const emitter = new Subject<string>();
    const img = document.createElement('img');
    img.onload = function() {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        
        const graphics = canvas.getContext('2d');
        const halfWidth = canvas.width * 0.5;
        const halfHeight = canvas.height * 0.5;
        graphics!.translate(halfWidth, halfHeight);
        graphics!.rotate(90 * Math.PI / 180);
        graphics!.drawImage(img, -halfWidth, -halfHeight);
        emitter.next(canvas.toDataURL());
    }
    
    img.crossOrigin = 'anonymous';
    img.src = url;
    return emitter;
}