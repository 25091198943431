import { Injectable } from "@angular/core";
import { UserService } from "./user-service";
import { Observable, of } from "rxjs";
import { UserDto } from "./user-dto";
import { CredentialStorage } from "./credentials-storage";
import { Credentials } from "./credentials";

@Injectable({
    providedIn: 'root'
})
export class UserAuthenticator {
    constructor(
        private credentialStorage: CredentialStorage,
        private userService: UserService
    ) { }

    tryLoginFromStorage(): Observable<UserDto | null> {
        const credentials = this.credentialStorage.current();

        if(credentials === null)
            return of(null);

        return this.tryLogin();
    }

    login(credentials: Credentials, remember: boolean): Observable<UserDto | null> {
        this.credentialStorage.save(credentials);
        return this.tryLogin();
    }

    private tryLogin(): Observable<UserDto | null> {
        return this.userService.get();
    }
}