import { Component, Inject, OnInit } from "@angular/core";
import { Integration } from "./integration";
import { XenaIntegrationState } from "./xena-integration-state";
import { INTEGRATION_SERVICE_TOKEN, IntegrationService } from "./integrations-service";
import { KeatechIntegrationState } from "./keatech-integration-state";

@Component({
    selector: 'integrations-page',
    templateUrl: 'integrations-page.component.html',
    styleUrls: ['integrations-page.component.scss']
})
export class IntegrationsPageComponent implements OnInit {
    keatech: Integration<KeatechIntegrationState>;
    xena: Integration<XenaIntegrationState>;

    constructor(
        @Inject(INTEGRATION_SERVICE_TOKEN) service: IntegrationService
    ) {
        this.keatech = new Integration(new KeatechIntegrationState(service));
        this.xena = new Integration(new XenaIntegrationState(service));
    }

    ngOnInit(): void {
        this.keatech.load();
        this.xena.load();
    }
}