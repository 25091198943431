<div class="employee-list">
    <employee
        *ngFor="let employee of employees"
        [department]="employee.department"
        [id]="employee.publicId"
        [name]="employee.name"
        [title]="employee.title"
        [imageUrl]="employee.imageUrl"
        [activity]="employee.activitySince(today())">
    </employee>
</div>

<ng-template shellHeader>
    <search-header
        [selectedSorting]="EmployeeSorting.NameAscending"
        [selectedActivity]="Activity.Active"
        [sortingOptions]="sortingOptions"
        [activityOptions]="activityOptions"
        (selectedActivityChange)="filter($event)"
        (selectedSortingChange)="sort($event)"
        (searched)="search($event)"
        [selectedEntity]="EntityType.Employee">
    </search-header>
</ng-template>