import { NgModule } from "@angular/core";
import { EquipmentCreateComponent } from "./equipment-create.component";
import { EquipmentModule } from "../equipment.module";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { ButtonsModule } from "src/components/buttons/buttons.module";
import { LayoutModule } from "src/layout/layout.module";
import { ComponentsModule } from "src/components/components.module";
import { CategoriesModule } from "src/categories/categories.module";
import { ImagesModule } from "src/images/images.module";
import { AttachmentsModule } from "src/attachments/attachments.module";

@NgModule({
    declarations: [
        EquipmentCreateComponent
    ],
    exports: [
        EquipmentCreateComponent
    ],
    imports: [
        EquipmentModule,
        FontAwesomeIconsModule,
        ButtonsModule,
        LayoutModule,
        ComponentsModule,
        CategoriesModule,
        ImagesModule,
        AttachmentsModule,
    ]
})

export class EquipmentCreateModule {

}