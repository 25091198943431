import { NgModule } from "@angular/core";
import { CategoryListComponent } from "./category-list.component";
import { AsyncPipe, NgForOf, NgIf, SlicePipe } from "@angular/common";
import { CategoryChipComponent } from "./category-chip.component";
import { SearchModule } from "src/search/search.module";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { ReadOnlyChipListComponent } from "./readonly-chip-list.component";

@NgModule({
    declarations: [
        CategoryListComponent,
        CategoryChipComponent,
        ReadOnlyChipListComponent
    ],
    exports: [
        CategoryListComponent,
        CategoryChipComponent,
        ReadOnlyChipListComponent
    ],
    imports: [
        NgForOf,
        SearchModule,
        NgIf,
        AsyncPipe,
        FontAwesomeIconsModule,
        SlicePipe
    ]
})
export class CategoriesModule { }