import { Observable } from "rxjs";

export interface IntegrationState {
    activate(): Observable<any>;
    deactivate(): Observable<any>;
    load(): Observable<boolean>;
}

export class Integration<State extends IntegrationState = any> {
    isActivated: boolean = false;

    constructor(
        public readonly value: State
    ) { }

    private activate() {
        this.isActivated = true;
        this.value.activate().subscribe();
    }

    private deactivate() {
        this.isActivated = false;
        this.value.deactivate().subscribe();
    }

    load() {
        this.value
            .load()
            .subscribe(isActivated => this.isActivated = isActivated);
    }

    onToggled(activated: boolean) {
        if(activated)
            this.activate();
        else
            this.deactivate();
    }
}