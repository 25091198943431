import { Component } from "@angular/core";
import { InputType } from "src/components/create/createInput.component";

@Component({
    templateUrl: './employee-create.component.html',
    styleUrls: ['./employee-create.component.scss']
})

export class EmployeeCreateComponent {
    get InputType(): typeof InputType {
        return InputType;
    }
}