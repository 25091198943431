import { NgModule } from "@angular/core";
import { ImageGalleryComponent } from "./imageGallery.component";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { CommonModule, NgIf } from "@angular/common";
import { AddImagesComponent } from "./addImages.component";
import { ButtonsModule } from "src/components/buttons/buttons.module";

@NgModule({
    declarations: [
        ImageGalleryComponent,
        AddImagesComponent
    ],
    exports: [
        ImageGalleryComponent,
        AddImagesComponent
    ],
    imports: [
        FontAwesomeIconsModule,
        ButtonsModule,
        CommonModule
    ]
})
export class ImagesModule { }