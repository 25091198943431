import { ProductItemOrder } from "./product-item-order";
import { ProductItemStatus } from "./product-item-status";

export class ProductItemQuery {
    offset: number = 0;
    count: number = 25;
    ordering?: ProductItemOrder;
    status?: ProductItemStatus

    toParams(): any {
        return {
            offset: this.offset,
            count: this.count,
            ordering: this.ordering ?? ProductItemOrder.NameAscending,
            status: this.status ?? null
        };
    }
}