import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Injectable, inject } from "@angular/core";
import { UserAuthenticator } from "./user-authenticator";
import { Observable, from, map, of, switchMap } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UserGuard {
    constructor(
        private router: Router,
        private authenticator: UserAuthenticator
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {    
        return this.authenticator
            .tryLoginFromStorage()
            .pipe(
                switchMap(user => {
                    if(user !== null){
                        return of(true);
                    }
                    
                    return from(this.router.navigate(['login']))
                        .pipe(
                            map(_ => false) 
                        );
                })
            );
        
    }

    static canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return inject(UserGuard).canActivate(route, state);
    }
}