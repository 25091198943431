import { Component, Input } from "@angular/core";
import { Category } from "./category";

@Component({
    selector: 'readonly-chip-list',
    templateUrl: 'readonly-chip-list.component.html',
    styleUrls: ['readonly-chip-list.component.scss']
})
export class ReadOnlyChipListComponent {
    @Input()
    categories: Category[] = [];
}