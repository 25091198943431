import { Component, OnInit } from "@angular/core";
import { CredentialStorage } from "./credentials-storage";
import { Router } from "@angular/router";
import { SettingsStorage } from "src/settings/settings-storage";
import { Theme } from "src/settings/settings";
import { from } from "rxjs";

@Component({
    selector: 'user-panel',
    templateUrl: 'userPanel.component.html',
    styleUrls: ['userPanel.component.scss']
})

export class UserPanelComponent implements OnInit {
    isDark: boolean = false;

    constructor(
        private router: Router,
        private storage: CredentialStorage,
        private settingsStorage: SettingsStorage
    ) { }

    ngOnInit(): void {
        const settings = this.settingsStorage.load();
        this.isDark = settings.theme === Theme.Dark;
        
        if(this.isDark)
            this.darkMode();
    }

    async logout() {
        this.storage.clear();
        await this.router.navigate(['login']);
    }

    private isInDarkMode(): boolean {
        return document.documentElement.getAttribute("data-theme") === "dark";
    }

    darkMode(): void {
        document.documentElement.setAttribute("data-theme", "dark");
        this.isDark = true;
    }

    lightMode(): void {
        document.documentElement.setAttribute("data-theme", "light");
        this.isDark = false;
    }

    private updateSettings() {
        const settings = this.settingsStorage.load();
        settings.theme = this.isDark ? Theme.Dark : Theme.Light;
        this.settingsStorage.save(settings);
    }

    toggle(): void {
        if(this.isInDarkMode()) {
            this.lightMode();
        } else {
            this.darkMode();
        }
        
        this.updateSettings();
    }

    integrations(): void {
        from(this.router.navigateByUrl('/integrations')).subscribe();
    } 

    createEmployee(): void {
        from(this.router.navigateByUrl('/employees/create')).subscribe();
    }

    createEquipment(): void {
        from(this.router.navigateByUrl('/equipment/create')).subscribe();
    }
}