import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { ImageGalleryComponent } from "src/images/imageGallery.component";
import { IPhotoService, PHOTO_SERVICE } from "./photo-service";
import { Image, rotateImage } from './image';
import { Observable, map, switchMap, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { photoToImage } from "./photoDto";

@Component({
    selector: 'add-images',
    templateUrl: 'addImages.component.html',
    styleUrls: ['addImages.component.scss']
})

export class AddImagesComponent implements OnInit {
    @ViewChild(ImageGalleryComponent, { static: true })
    gallery!: ImageGalleryComponent;

    @Input()
    ownerId: string = '';

    images: Image[] = [];

    constructor(
        @Inject(PHOTO_SERVICE) private service: IPhotoService,
        private readonly client: HttpClient
    ) { }

    private reloadAll(): Observable<Image[]> {
        return this.service
            .get(this.ownerId)
            .pipe(
                map(x => x.map(photoToImage)),
                tap(images => this.images = images)
            );
    }

    ngOnInit(): void {
        this.reloadAll()
            .subscribe();
    }

    delete() {
        const index = this.gallery.selectedIndex;
        const id = this.images[index].id;
        this.service
            .delete(id)
            .subscribe(_ => {
                this.images.splice(index, 1);
                this.gallery.select(0);
            });
    }

    favorite() {
        const id = this.gallery.selected.value?.id;

        if(id === undefined)
            return;

        this.service.setDefault(id).subscribe();
    }

    private onAdded(file: File) {
        this.service
            .create(this.ownerId)
            .pipe(
                switchMap(imageId => 
                    this.service
                        .upload(imageId, file)
                        .pipe(map(_ => imageId))
                )
            )
            .subscribe(imageId => {
                const url = URL.createObjectURL(file);
                const image: Image = { id: imageId, url, isFavorited: false };
                this.images.push(image);
                this.gallery.select(0);
            });
    }

    add() {
        const fileDialog = document.createElement('input');
        fileDialog.type = 'file';

        fileDialog.addEventListener('change', () => {
            let file = fileDialog.files?.item(0) ?? null;

            if(file !== null)
                this.onAdded(file);
        });

        fileDialog.click();
    }

    dropHandler(ev: DragEvent): void {
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();

        if (ev.dataTransfer?.items) {
            // Use DataTransferItemList interface to access the file(s)
            Array.from(ev.dataTransfer.items).forEach((item: DataTransferItem) => {
                // If dropped items aren't files, reject them
                if (item.kind === "file") {
                    const file: File | null = item.getAsFile();
        
                    if (file) {
                        this.onAdded(file);
                    }
                }
            });
        } else {
            // Use DataTransfer interface to access the file(s)
            Array.from(ev.dataTransfer?.files || [])
                .forEach((file: File) => this.onAdded(file));
        }
    }

    dragOverHandler(ev: DragEvent): void {
        ev.preventDefault();
    }

    rotate() {
        const url = this.gallery.selected.value?.url;
        
        if(url === undefined)
            return;
        
        const id = this.gallery.selected.value?.id;
        const selectedIndex = this.gallery.selectedIndex;
        rotateImage(url)
            .pipe(
                tap(rotatedUrl => this.images[selectedIndex].url = rotatedUrl),
                switchMap(rotatedUrl => this.client.get(rotatedUrl, { responseType: 'blob' })),
                switchMap(rotated =>
                    this.service
                        .upload(id!, rotated)
                        .pipe(
                            switchMap(_ => this.reloadAll())
                        )
                    )
            )
            .subscribe(_ => this.gallery.select(selectedIndex))
    }
}