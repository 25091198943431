import { Directive, Input, Output } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

@Directive({
    selector: 'log-item'
})

export class LogItem {
    @Output()
    expanded: boolean = false;

    @Input()
    icon: IconProp = 'wrench';

    @Input()
    title: string = '';

    @Input()
    date: string = '';

    @Input()
    text: string = '';

    toggle() {
        this.expanded = !this.expanded;
    }
}