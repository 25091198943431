<integration 
    [text]="'xenaIntegrationDescription' | localize"
    [added]="state.isActivated"
    (addedChange)="state.onToggled($event)"
    imageUrl="https://xena.biz/media/fl2bdtug/xenaeglogogreen.png">
    <create-input 
        [readonly]="state.isActivated"
        [(value)]="state.value.fiscalId"
        placeholder="Fiscal Id"
        title="Fiscal Id"
        [required]="true">
    </create-input>

    <create-input 
        [readonly]="state.isActivated"
        [(value)]="state.value.apiKey"
        placeholder="Api Key"
        title="Api Key"
        [required]="true">
    </create-input>
</integration>