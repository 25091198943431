import { Directive, EventEmitter, Input, Output } from "@angular/core";

@Directive({
    selector: 'subpage-switch-button'
})
export class SubPageSwitchButtonComponent {
    @Input()
    title: string = '';

    @Input()
    selected: boolean = false;

    @Output()
    selectedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    setSelected(value: boolean) {
        this.selected = value;
        this.selectedChange.emit(value);
    }
}