import { EquipmentSize } from "src/equipment/equipment-size";
import { Component, Input } from "@angular/core";

@Component({
    selector: 'infobox-services',
    templateUrl: 'infoboxServices.component.html',
    styleUrls: ['infoboxServices.component.scss']
})

export class InfoboxServicesComponent {
    @Input()
    serviceType: string = '';

    get EquipmentSize(): typeof EquipmentSize {
        return EquipmentSize;
    }
}