import { Directive, Input } from "@angular/core";
import { MARGIN } from "./chartRing.component";
import { Circle } from "src/geometry/circle";
import { lerp } from "src/math";
 
export const COLORS: Map<string, string> = new Map([
    [ 'red', '#CB4B4D' ],
    [ 'blue', '#477BBA' ],
    [ 'green', '#87D978' ],
    [ 'yellow', '#E5A50B' ]
]);

@Directive({
    selector: 'chart-ring-part'
})
export class ChartRingPart {
    @Input()
    weight: number = 0;

    @Input()
    text: string = '';

    private _color: string = '';

    @Input()
    get color(): string {
        return this._color;
    }

    set color(value: string) {
        this._color = COLORS.get(value) ?? value;
    }

    draw(
        graphics: CanvasRenderingContext2D, 
        from: number, 
        to: number, 
        circle: Circle,
        thickness: number,
        animationTime: number
    ): void {
        const r = lerp(circle.radius - MARGIN, circle.radius + MARGIN, animationTime); 
        const c = circle.withRadius(r);
        const start = lerp(from, from + 0.1, animationTime);
        const end = lerp(to, to - 0.1, animationTime);
        const t = lerp(thickness, thickness + MARGIN, animationTime); 
        c.drawPart(graphics, start, end, this.color, t);
    }
}