import { NgModule } from "@angular/core";
import { LocalizeContent, LocalizePipe, LocalizeProperty } from "./localize";

@NgModule({
    declarations: [
        LocalizeContent,
        LocalizeProperty,
        LocalizePipe
    ],
    exports: [
        LocalizeContent,
        LocalizeProperty,
        LocalizePipe
    ]
})
export class LocalizationModule { }