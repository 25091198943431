export const MILLISECONDS_IN_2_WEEKS = 1209600000;
const JAVSCRIPT_DATE_FORMAT = /([0-9]{4})-([0-9]{2})-([0-9]{2})T([0-9]{2}):([0-9]{2}):([0-9]{2}).([0-9]{3})Z UTC/;
export const MILLISECONDS_IN_A_SECOND = 1000;
export const MILLISECONDS_IN_A_MINUTE = MILLISECONDS_IN_A_SECOND * 60;
export const MILLISECONDS_IN_A_HOUR= MILLISECONDS_IN_A_MINUTE * 60;
export const MILLISECONDS_IN_A_DAY= MILLISECONDS_IN_A_HOUR * 24;

export function fromJavascriptDateTime(dateTime: string): Date {
    let result = JAVSCRIPT_DATE_FORMAT.exec(dateTime);

    if(result == null)
        return new Date();

    let year = Number.parseInt(result[1]);

    // If you read the documentation, apparently month is zero based..
    // Doc: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
    let month = Number.parseInt(result[2]) - 1;
    let day = Number.parseInt(result[3]);
    let hours = Number.parseInt(result[4]);
    let minutes = Number.parseInt(result[5]);
    let seconds = Number.parseInt(result[6]);
    let millis = Number.parseInt(result[7]);
    return new Date(Date.UTC(year, month, day, hours, minutes, seconds, millis));
}

export function toJavscriptDateTime(date: Date): string {
    const day = date.getUTCDate().toString().padStart(2, '0');
    // If you read the documentation, apparently month is zero based..
    // Doc: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/UTC
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString().padStart(4, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');
    const millis = date.getUTCMilliseconds().toString().padStart(3, '0');
    return `${year}-${month}-${day}T${hours}:${month}:${seconds}.${millis}Z UTC`;
}

export function diffMs(a: Date, b: Date) {
    return Math.abs(a.getTime() - b.getTime());
}

export function todayOffsetByDays(offsetInDays: number): Date {
    const now = new Date(Date.now());
    const offsetDate = new Date()
        .setDate(now.getDate() + offsetInDays);

    return new Date(offsetDate);
}

export function millisecondsToDays(ms: number): number {
    return ms / MILLISECONDS_IN_A_DAY;
}