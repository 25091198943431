<div class="equipment-list">
    <equipment
        *ngFor="let item of equipment"
        [id]="item.publicId"
        [brand]="item.brand"
        [info]="item.info"
        [isHandedOut]="item.isHandedOut"
        [thumbnail]="item.thumbnail">
    </equipment>
</div>

<ng-template shellHeader>
    <search-header 
        [selectedActivity]="ProductItemStatus.InStock"
        [selectedSorting]="ProductItemOrder.NameAscending"
        [sortingOptions]="sortingOptions" 
        [activityOptions]="activityOptions"
        (selectedActivityChange)="filter($event)"
        (selectedSortingChange)="sort($event)"
        (searched)="search($event)"
        [selectedEntity]="EntityType.Equipment">
    </search-header>
</ng-template>