import { NgModule } from "@angular/core";
import { EmployeeSearchComponent } from "./employee-search.component";
import { LayoutModule } from "src/layout/layout.module";
import { EmployeesModule } from "../employees.module";
import { CommonModule } from "@angular/common";
import { SearchModule } from "src/search/search.module";

@NgModule({
    declarations: [
        EmployeeSearchComponent
    ],
    exports: [
        EmployeeSearchComponent
    ],
    imports: [
        LayoutModule,
        EmployeesModule,
        CommonModule,
        SearchModule
    ]
})
export class EmployeeSearchModule { }