<div class="employee-create">
    <div class="left">
        <div class="employee-create-topbar">
            <icon-button icon="chevron-left"></icon-button>
            <span class="employee-create-topbar-title">Opret Medarbejder</span>
            <fa-icon icon="user-plus"></fa-icon>
        </div>
        <div class="employee-create-grid">
            <create-input class="employee-create-grid-id" title="Bruger ID" placeholder="ex. 423011"></create-input>
            <dropdown class="employee-create-grid-title" title="Stilling">
                <dropdown-option icon="user" text="Medarbejder" iconColor="grey"></dropdown-option>
                <dropdown-option icon="user-shield" text="Administrator" iconColor="grey"></dropdown-option>
            </dropdown>
            <create-input class="employee-create-grid-name" title="Navn" placeholder="Jim Lahey"></create-input>
            <dropdown class="employee-create-grid-department" title="Afdeling">
                <dropdown-option icon="warehouse" text="TraceTool Central" iconColor="grey"></dropdown-option>
                <dropdown-option icon="warehouse" text="TraceTool East" iconColor="grey"></dropdown-option>
                <dropdown-option icon="warehouse" text="TraceTool North" iconColor="grey"></dropdown-option>
            </dropdown>
            <create-input class="employee-create-grid-phone" title="Mobil" placeholder="42 42 42 42" [type]="InputType.Phone"></create-input>
            <create-input class="employee-create-grid-email" title="Email" placeholder="Jim.Lahey@gmail.com" [type]="InputType.Email"></create-input>
            <div class="attachments-container">
                <p class="attachments-container-title">Vedhæftede Filer</p>
                <attachments></attachments>
            </div>
            <add-images></add-images>
        </div>
    </div>
    <div class="employee-create-roles">
        <span class="employee-create-roles-title">Roller</span>
        <div class="employee-create-roles-list">
            <create-role [amount]="25" [maxAmount]="30" title="Medarbejder" text="Basis adgang til TraceTool Thor" price="50">
                <create-role-feature text="Overførsel"></create-role-feature>
                <create-role-feature text="Servicering"></create-role-feature>
            </create-role>
            <create-role icon="user-shield" [amount]="5" [maxAmount]="5" title="Administrator" text="Administrator adgang til TraceTool Thor" price="500">
                <create-role-feature text="Overførsel"></create-role-feature>
                <create-role-feature text="Servicering"></create-role-feature>
                <create-role-feature text="Oprettelse af Medarb. og Værktøj"></create-role-feature>
            </create-role>
        </div>
        <icon-text-button icon="check" text="Færdiggør Oprettelse" shape="square"></icon-text-button>
    </div>
</div>