import { Component, Input } from "@angular/core";

@Component({
    selector: 'copy-button',
    templateUrl: 'copy-button.component.html',
    styleUrls: ['copy-button.component.scss']
})
export class CopyButtonComponent {
    @Input()
    text: string = '';

    showTooltip: boolean = false;

    clicked() {
        navigator.clipboard.writeText(this.text);
        this.showTooltip = true;
    }
}