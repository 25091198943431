import { Component, Input } from "@angular/core";
import { GallerySize } from "src/images/image-gallery-size";
import { EquipmentSize } from "./equipment-size";
import { Category } from "src/categories/category";
import { Image } from "src/images/image";

@Component({
    selector: 'equipment',
    templateUrl: 'equipment.component.html',
    styleUrls: ['equipment.component.scss']
})
export class EquipmentComponent {
    @Input()
    id: string = '';

    @Input()
    brand: string = '';

    @Input()
    info: string = '';

    @Input()
    thumbnail: Image = new Image('', '', false);

    @Input()
    isHandedOut: boolean = false;

    @Input()
    categories: Category[] = [];

    @Input()
    type: EquipmentSize = EquipmentSize.Medium;

    get EquipmentSize(): typeof EquipmentSize {
        return EquipmentSize;
    }

    get GallerySize(): typeof GallerySize {
        return GallerySize;
    }
}