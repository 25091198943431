import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";

@Component({
    selector: 'search-bar',
    templateUrl: 'searchBar.component.html',
    styleUrls: ['searchBar.component.scss']
})
export class SearchBarComponent {
    @Input()
    expandOnFocus: boolean = true;

    @Output()
    search: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('searchInput')
    searchInput!: ElementRef<HTMLInputElement>;

    onSearch(text: string): void {
        this.search.emit(text);
    }

    focus() {
        requestAnimationFrame(() => this.searchInput.nativeElement.focus());
    }
}