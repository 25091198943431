<ng-template #closedTemplate>
    <div class="container" (click)="toggle()">
        <span class="container-title">{{title}}</span>
        <fa-icon icon="caret-left"></fa-icon>
    </div>
</ng-template>

<ng-container *ngIf="expanded; else closedTemplate">
    <div class="container" (click)="toggle()">
        <span class="container-title">{{title}}</span>
        <fa-icon icon="caret-down"></fa-icon>
    </div>
    <div class="grid">
        <button class="grid-item" *ngFor="let item of items">
            <span class="grid-item-title">{{item.subtitle}}</span>
            <span class="grid-item-text">{{item.text}}</span>
        </button>
    </div>
</ng-container>