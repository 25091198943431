<p class="title">Kategori *</p>
<div class="container">
    <div class="chip-container">
        <category-chip 
            *ngFor="let category of categories" 
            [name]="category.name" 
            [readonly]="false"
            (click)="remove(category)">
        </category-chip>
    </div>
    <fa-icon 
        (click)="show()"
        class="indicator"
        [icon]="isOpen ? 'caret-down' : 'caret-left'">
    </fa-icon>
    <div #dropdownContainer class="dropdown" [style.display]="isOpen ? 'block' : 'none'">
        <div class="dropdown-content">
            <search-bar class="search-bar" [expandOnFocus]="false" (search)="search($event)"></search-bar>
            <div class="search-result-container">
                <category-chip 
                    *ngFor="let result of (searchResults | async)" 
                    [name]="result.name"
                    class="item"
                    (click)="add(result)">
                </category-chip>
            </div>
        </div>
    </div>
</div>