import { Injectable } from "@angular/core";
import { Credentials } from "./credentials";

const AUTH_HEADER_KEY: string = "tracetool.auth";

@Injectable({
    providedIn: 'root'
})
export class CredentialStorage {
    private credentials: Credentials | null = null;
    
    current(): Credentials | null {
        if(this.credentials === null) {
            this.credentials = this.load();
        }

        return this.credentials;
    }

    clear() {
        this.credentials = null;
        localStorage.removeItem(AUTH_HEADER_KEY);
    }

    save(credentials: Credentials): void {
        localStorage.setItem(AUTH_HEADER_KEY, credentials.authHeader);
    }

    private load(): Credentials | null {
        const authHeader = localStorage.getItem(AUTH_HEADER_KEY);

        if(authHeader === null)
            return null;

        return new Credentials(authHeader);
    }
}