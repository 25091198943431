<div class="container">
    <search-bar (search)="searched.emit($event)"></search-bar>
    <dropdown [selected]="selectedEntity" (selectedChange)="entitySelected($event)">
        <dropdown-option [value]="EntityType.Equipment" icon="hammer" iconColor="grey" text="Værktøj"></dropdown-option>
        <dropdown-option [value]="EntityType.Employee" icon="user" iconColor="grey" text="Medarbejdere"></dropdown-option>
    </dropdown>
    <dropdown [(selected)]="selectedActivity">
        <dropdown-option *ngFor="let option of activityOptions"
            [text]="option.text"
            [icon]="option.icon"
            [value]="option.value"
            [iconColor]="option.iconColor">
        </dropdown-option>
    </dropdown>
    <dropdown  [(selected)]="selectedSorting">
        <dropdown-option *ngFor="let option of sortingOptions"
            [text]="option.text"
            [icon]="option.icon"
            [value]="option.value"
            [iconColor]="option.iconColor">
        </dropdown-option>
    </dropdown>
</div>