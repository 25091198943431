<div class="infobox-services">
    <span class="infobox-services-title">Kommende Services</span>
    <div class="infobox-services-date">
        <fa-icon icon="calendar-day"></fa-icon>
        <span>12 / 10 - 2022</span>
    </div>
    <equipment id="1028" brand="Makita" info="480 V | Variabel Hastighed" [type]="EquipmentSize.Small"></equipment>
    <div class="infobox-paginator">
        <fa-icon icon="chevron-left"></fa-icon>
        <span>{{serviceType}}</span>
        <fa-icon icon="chevron-right"></fa-icon>
    </div>
</div>