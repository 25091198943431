<div class="equipment-create">
    <div class="left">
        <div class="equipment-create-topbar">
            <icon-button icon="chevron-left"></icon-button>
            <span class="equipment-create-topbar-title">Opret Værktøj</span>
            <fa-icon icon="hammer"></fa-icon>
        </div>
        <div class="equipment-create-grid">
            <create-input class="equipment-create-grid-model" title="Model" placeholder="450 W | Variabel Hastighed"></create-input>
            <dropdown class="equipment-create-grid-make" title="Fabrikat">
                <dropdown-option text="Makita" icon="hammer" iconColor="grey"></dropdown-option>
            </dropdown>
            <category-list class="equipment-create-grid-categories" [categories]="[{ id: '123', name: 'borehammer' }, { id: '321', name: 'boremaskine' }, { id: '1234', name: 'søm' }]"></category-list>
            <dropdown class="equipment-create-grid-owner" title="Ihændehaver/Lager">
                <dropdown-option text="TraceTool Central" icon="warehouse" iconColor="grey"></dropdown-option>
                <dropdown-option text="TraceTool East" icon="warehouse" iconColor="grey"></dropdown-option>
                <dropdown-option text="TraceTool South" icon="warehouse" iconColor="grey"></dropdown-option>
            </dropdown>
            <div class="equipment-create-grid-scancode">
                <create-input title="Stregkode" placeholder="DJF340534JL"></create-input>
                <icon-button icon="qrcode" shape="square"></icon-button>
            </div>
            <div class="equipment-create-grid-barcode">
                <create-input title="Serienummer" placeholder="64593344"></create-input>
                <icon-button icon="barcode" shape="square"></icon-button>
            </div>
            <create-input class="equipment-create-grid-date" title="Indkøbsdato" [type]="InputType.Date"></create-input>
            <create-input class="equipment-create-grid-price" title="Indkøbspris" placeholder="0 DKK"></create-input>
            <comment-field class="equipment-create-grid-details" title="Detaljer"></comment-field>
            <add-images></add-images>
            <div class="attachments-container">
                <p class="attachments-container-title">Vedhæftede Filer</p>
                <attachments></attachments>
            </div>
            <div class="equipment-create-grid-services">
            <p class="equipment-create-grid-services-title">Tilføj Services</p>
                <collapsible-grid title="Kalibrering">
                    <grid-item text="1 år"></grid-item>
                    <grid-item text="6 mdr"></grid-item>
                    <grid-item text="3 mdr"></grid-item>
                </collapsible-grid>
                <collapsible-grid title="Vedligeholdelse">
                    <grid-item text="1 år"></grid-item>
                    <grid-item text="6 mdr"></grid-item>
                    <grid-item text="1 md"></grid-item>
                </collapsible-grid>
                <collapsible-grid title="Eftersyn">
                    <grid-item text="1 år"></grid-item>
                    <grid-item text="2 år"></grid-item>
                    <grid-item text="3 år"></grid-item>
                </collapsible-grid>
                <icon-text-button icon="plus" text="Tilføj Service"></icon-text-button>
            </div>
        </div>
    </div>
    <div class="equipment-create-suggestions">
        <span class="equipment-create-suggestions-title">Forslag</span>
        <div class="equipment-create-suggestions-list">
            <equipment *ngFor="let item of suggestions" [type]="EquipmentSize.Create" [brand]="item.brand" [info]="item.info" [categories]="item.categories"></equipment>
            <equipment *ngFor="let item of suggestions" [type]="EquipmentSize.Create" [brand]="item.brand" [info]="item.info" [categories]="item.categories"></equipment>
            <equipment *ngFor="let item of suggestions" [type]="EquipmentSize.Create" [brand]="item.brand" [info]="item.info" [categories]="item.categories"></equipment>
        </div>
        <icon-text-button icon="check" text="Færdiggør Oprettelse" shape="square"></icon-text-button>
    </div>
</div>