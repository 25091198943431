import { Component, Inject, OnInit } from "@angular/core";
import { IProductItemService, PRODUCT_ITEM_SERVICE } from "../product-item-service";
import { ProductItem } from "src/equipment/product-item";
import { ProductItemQuery } from "src/equipment/product-item-query";
import { DropdownOption, DropdownOptionBuilder } from "src/components/dropdown-option";
import { ProductItemOrder } from "src/equipment/product-item-order";
import { ProductItemStatus } from "src/equipment/product-item-status";
import { Observable } from "rxjs";
import { PagedQueryResultDto } from "src/pagedQueryResultDto";
import { ProductItemDto } from "src/equipment/product-item-dto";
import { EntityType } from "src/search/searchHeader.component";

@Component({
    templateUrl: 'equipment-search.component.html',
    styleUrls: [ 'equipment-search.component.scss' ]
})
export class EquipmentSearchComponent implements OnInit {
    equipment: ProductItem[] = [];
    ordering: ProductItemOrder = ProductItemOrder.NameAscending;
    status: ProductItemStatus = ProductItemStatus.InStock;
    text: string = '';
    sortingOptions: DropdownOption[];
    activityOptions: DropdownOption[];
    
    get ProductItemStatus(): typeof ProductItemStatus {
        return ProductItemStatus;
    }

    get ProductItemOrder(): typeof ProductItemOrder {
        return ProductItemOrder;
    }

    get EntityType(): typeof EntityType {
        return EntityType;
    }
    
    constructor(
        @Inject(PRODUCT_ITEM_SERVICE) private service: IProductItemService
    ) { 
        this.sortingOptions = EquipmentSearchComponent.sortingOptions();
        this.activityOptions = EquipmentSearchComponent.activityOptions();
    }

    ngOnInit(): void {
        this.reload();
    }

    private static activityOptions(): DropdownOption[] {
        const optionTemplate = new DropdownOptionBuilder().withIcon('circle');
        return [
            optionTemplate.withIconColor('green').withText('På lager').withValue(ProductItemStatus.InStock).build(),
            optionTemplate.withIconColor('red').withText('Udleveret').withValue(ProductItemStatus.HandedOut).build()
        ];
    }

    private static sortingOptions(): DropdownOption[] {
        const optionTemplate = new DropdownOptionBuilder().withIconColor('grey');
        return [
            optionTemplate.withIcon('arrow-down').withText('Navn').withValue(ProductItemOrder.NameAscending).build(),
            optionTemplate.withIcon('arrow-up').withText('Navn').withValue(ProductItemOrder.NameDescending).build(),
            optionTemplate.withIcon('arrow-down').withText('Oprettelsesdato').withValue(ProductItemOrder.CreationDateAscending).build(),
            optionTemplate.withIcon('arrow-up').withText('Oprettelsesdato').withValue(ProductItemOrder.CreationDateDescending).build()
        ];
    }

    sort(order: ProductItemOrder | undefined) {
        this.ordering = order ?? ProductItemOrder.NameAscending;
        this.reload();
    }

    filter(status: ProductItemStatus | undefined) {
        this.status = status ?? ProductItemStatus.InStock;
        this.reload();
    }

    search(text: string) {
        this.text = text;
        this.reload();
    }

    private task(query: ProductItemQuery): Observable<PagedQueryResultDto<ProductItemDto>> {
        if(this.text === '')
            return this.service.all(query);

        return this.service.search(this.text, query);
    }

    private reload() {
        const query = new ProductItemQuery();
        query.ordering = this.ordering;
        query.status = this.status;

        this.task(query)
            .subscribe(x => this.equipment = x.items.map(ProductItem.from));
    }
}