import { Component, ContentChildren, EventEmitter, Input, Output, QueryList } from "@angular/core";
import { DropdownOption } from "./dropdown-option";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: 'dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent {
    private _selected: any;
    private _options: QueryList<DropdownOption> | undefined;

    @ContentChildren(DropdownOption, { descendants: true })
    set options(value: QueryList<DropdownOption> | undefined) {
        this._options = value;
        this.update();
    }

    get options(): QueryList<DropdownOption> | undefined {
        return this._options;
    }

    @Input()
    set selected(value: any) {
        this._selected = value;
        this.update();
    }

    @Output()
    selectedChange = new EventEmitter<any>();

    @Input()
    title: string = '';

    selectedOption = new BehaviorSubject<DropdownOption| undefined>(undefined);

    update() {
        const selectedOption = this.options?.find(x => x.value === this._selected);
        this.selectedOption.next(selectedOption);
        this.selectedChange.emit(this._selected);
    }
}