import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { CreateResultDto } from "src/createResultDto";
import { environment } from "src/environments/environment";
import { PhotoDto } from "./photoDto";

export const PHOTO_SERVICE: string = 'PHOTO_SERVICE';

export interface IPhotoService {
    setDefault(id: string): Observable<any>;
    delete(id: string): Observable<any>;
    create(ownerId: string): Observable<string>;
    upload(id: string, data: Blob): Observable<any>;
    get(ownerId: string): Observable<PhotoDto[]>;
}

@Injectable()
export class PhotoService implements IPhotoService {
    constructor(
        private client: HttpClient
    ) { }

    setDefault(id: string): Observable<any> {
        const url = `${environment.webApiUrl}Photos/${id}/IsDefault`;  
        return this.client.put(url, true); 
    }

    delete(id: string): Observable<any> {
        const url = `${environment.webApiUrl}Photos/${id}`;  
        return this.client.delete(url); 
    }

    create(ownerId: string): Observable<string> {
        const url = `${environment.webApiUrl}Photos`;
        return this.client
            .post<CreateResultDto>(url, { id: ownerId })
            .pipe(map(x => x.id));
    }

    upload(id: string, data: Blob): Observable<any>{
        const url = `${environment.webApiUrl}Photos/${id}`;  
        return this.client.put(url, data);
    }

    get(ownerId: string): Observable<PhotoDto[]> {
        const url = `${environment.webApiUrl}Photos`
        const parameters = {
            'ownerId': ownerId
        };
        return this.client.get<PhotoDto[]>(url, { params: parameters });
    }
}