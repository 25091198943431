import { NgModule } from "@angular/core";
import { DropdownComponent } from "./dropdown.component";
import { DropdownOption } from "./dropdown-option";
import { CheckboxTextComponent } from "./checkboxText.component";
import { CommentFieldComponent } from "./commentField.component";
import { LogComponent } from "./log.component";
import { CounterComponent } from "./counter.component";
import { CreateInputComponent } from "./create/createInput.component";
import { CreateServiceComponent } from "./create/createService.component";
import { CollapsibleGridComponent } from "./collapsibles/collapsibleGrid.component";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { CommonModule } from "@angular/common";
import { ImagesModule } from "src/images/images.module";
import { PaginatorModule } from "src/pagination/paginator.module";
import { ButtonsModule } from "./buttons/buttons.module";
import { DropdownMenuTrigger } from "./dropdown-menu-trigger";
import { GridItem } from "./collapsibles/grid-item.component";
import { LogItem } from "./log-item.component";

@NgModule({
    declarations: [
        DropdownComponent,
        DropdownOption,
        CheckboxTextComponent,
        CommentFieldComponent,
        LogComponent,
        LogItem,
        CounterComponent,
        CreateInputComponent,
        CreateServiceComponent,
        CollapsibleGridComponent,
        GridItem,
        DropdownMenuTrigger
    ],
    exports: [
        DropdownComponent,
        DropdownOption,
        CheckboxTextComponent,
        CommentFieldComponent,
        LogComponent,
        LogItem,
        CounterComponent,
        CreateInputComponent,
        CreateServiceComponent,
        CollapsibleGridComponent,
        DropdownMenuTrigger,
        GridItem
    ],
    imports: [
        FontAwesomeIconsModule,
        ImagesModule,
        ButtonsModule,
        CommonModule,
        PaginatorModule,
    ]
})
export class ComponentsModule { }