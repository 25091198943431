import { IconProp } from "@fortawesome/fontawesome-svg-core";

export function iconFor(extension: string): IconProp {
    switch(extension) {
        case 'pdf': 
            return 'file-pdf';

        case 'xlsx': 
        case 'xlsm': 
        case 'xlsb': 
        case 'xltx': 
            return 'file-excel';

        case 'docx':
        case 'doc':
        case 'docm':
            return 'file-word';

        case 'zip':
        case 'gzip':
        case 'rar':
        case '7zip':
            return 'file-zip';

        case 'mp4':
        case 'webm':
        case 'ogg':
        case 'ogv':
        case 'gif':
        case 'avi':
        case 'mpg':
        case 'mpeg':
            return 'file-video';

        case 'png':
        case 'jpg':
        case 'jpeg':
            return 'file-image';

        case 'csv':
            return 'file-csv';

        default: return 'arrow-up-right-from-square';
    }
}

export function extensionOf(filename: string): string | null {
    const extensions = filename.split('.');

    if(extensions.length === 0)
        return null;

    return extensions[extensions.length - 1];
}