<div class="attachments">
  <p class="attachments-title">{{title}}</p>
  <paginator>
    <paginator-page *ngFor="let page of pages">
      <ng-template>
        <div class="paginator">
          <attachment *ngFor="let item of page.items" [filename]="item.filename" [displayName]="item.displayName" [url]="item.url"></attachment>
          <attachment-add *ngIf="page.items.length < 4" (added)="onAttachmentAdded($event)"></attachment-add>
        </div>
      </ng-template>
    </paginator-page>
  </paginator>
</div>