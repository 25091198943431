import { NgModule } from "@angular/core";
import { EquipmentDetailsComponent } from "./equipment-details.component";
import { ServicesModule } from "src/services/services.module";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { ComponentsModule } from "src/components/components.module";
import { ButtonsModule } from "src/components/buttons/buttons.module";
import { PaginatorModule } from "src/pagination/paginator.module";
import { EquipmentModule } from "../equipment.module";
import { InfoBoxModule } from "src/components/infoboxes/infoBox.module";
import { AttachmentsModule } from "src/attachments/attachments.module";
import { ChartsModule } from "src/components/charts/charts.module";
import { CommonModule } from "@angular/common";
import { EmployeesModule } from "src/employees/employees.module";
import { InfoBoxServicesModule } from "src/components/infoboxes/services/infoboxServices.module";

@NgModule({
    declarations: [
        EquipmentDetailsComponent
    ],
    exports: [
        EquipmentDetailsComponent
    ],
    imports: [
        ServicesModule,
        FontAwesomeIconsModule,
        ComponentsModule,
        ButtonsModule,
        PaginatorModule,
        EquipmentModule,
        InfoBoxModule,
        InfoBoxServicesModule,
        AttachmentsModule,
        ChartsModule,
        CommonModule,
        EmployeesModule,
    ]
})
export class EquipmentDetailsModule {}