<header class="user-panel">
    <a class="user-panel-btn">
      <span class="tooltip">Jobliste</span>
      <fa-icon icon="list-check"></fa-icon>
    </a>
    <a class="user-panel-btn">
      <span class="tooltip">Profil</span>
      <fa-icon icon="circle-user"></fa-icon>
    </a>
    <a dropdown-menu-trigger [menu]="settingsMenu" class="user-panel-btn">
      <span class="tooltip">Indstillinger</span>
      <fa-icon icon="gear"></fa-icon>
      <div #settingsMenu class="dropdown-menu">
        <a class="dropdown-menu-item" (click)="integrations()">
          <fa-icon icon="puzzle-piece"></fa-icon>
          <span class="dropdown-menu-text">Integrationer</span>
        </a>
        <a class="dropdown-menu-item" (click)="createEmployee()">
          <fa-icon icon="user-plus"></fa-icon>
          <span class="dropdown-menu-text">Opret Medarbejder</span>
        </a>
        <a class="dropdown-menu-item" (click)="createEquipment()">
          <fa-icon icon="hammer"></fa-icon>
          <span class="dropdown-menu-text">Opret Værktøj</span>
        </a>
      </div>
    </a>
    <a class="user-panel-btn" (click)="toggle()">
      <span class="tooltip">Skift Tema</span>
      <fa-icon [icon]="isDark ? 'sun' : 'moon'"></fa-icon>
    </a>
    <a class="user-panel-btn" (click)="logout()">
      <span class="tooltip">Log Ud</span>
      <fa-icon icon="arrow-right-from-bracket"></fa-icon>
    </a>
  </header>