import { Component, ContentChildren, Input, QueryList } from "@angular/core";
import { GridItem } from "./grid-item.component";

@Component({
    selector: 'collapsible-grid',
    templateUrl: 'collapsibleGrid.component.html',
    styleUrls: ['collapsibleGrid.component.scss']
})

export class CollapsibleGridComponent {
    @ContentChildren(GridItem, { descendants: false })
    items!: QueryList<GridItem>

    expanded: boolean = false;

    @Input()
    title: string = '';

    toggle() {
        this.expanded = !this.expanded;
    }
}