import { Category } from "src/categories/category";
import { ProductItemDto } from "./product-item-dto";
import { ProductItemType } from "./product-item-type";
import { Image } from 'src/images/image';

export class ProductItem {
    id: string = '';
    publicId: string = '';
    brand: string = '';
    info: string = '';
    thumbnail: Image = new Image('', '', false);
    isHandedOut: boolean = false;
    type: ProductItemType = ProductItemType.Unique;
    categories: Category[] = [];

    static from(dto: ProductItemDto): ProductItem {
        const item = new ProductItem();
        item.id = dto.id;
        item.publicId = dto.number;
        item.brand = dto.manufacturer;
        item.info = dto.description;
        item.type = dto.type;
        item.isHandedOut = dto.isHandedOut;
        item.thumbnail = new Image('', dto.thumbnailUrl, false);
        return item;
    }
}