import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeSearchComponent } from 'src/employees/search/employee-search.component';
import { EquipmentSearchComponent } from 'src/equipment/search/equipment-search.component';
import { LibraryComponent } from 'src/library/library.component';
import { LoginComponent } from 'src/login/login.component';
import { UserGuard } from 'src/users/user-guard';
import { EmployeeCreateComponent } from 'src/employees/employee-create.component';
import { EquipmentCreateComponent } from 'src/equipment/create/equipment-create.component';
import { EmployeeDetailsComponent } from 'src/employees/employee-details.component';
import { ExternalPagesComponent } from './external-pages.component';
import { IntegrationsPageComponent } from 'src/integrations/integrations-page.component';
import { AppShellComponent } from './app-shell.component';
import { EquipmentDetailsComponent } from 'src/equipment/details/equipment-details.component';

const routes: Routes = [
  { 
    path: 'external',
    component: ExternalPagesComponent, 
    children: [
      { path: 'integrations', component: IntegrationsPageComponent, canActivate: [ UserGuard.canActivate ] }
    ]
  },
  {
    path: '',
    component: AppShellComponent,
    children: [
      { path: 'library', component: LibraryComponent, canActivate: [ UserGuard.canActivate ] },
      { path: 'integrations', component: IntegrationsPageComponent, canActivate: [ UserGuard.canActivate ] },
      { path: 'employees', component: EmployeeSearchComponent, canActivate: [ UserGuard.canActivate ] },
      { path: 'equipment', component: EquipmentSearchComponent, canActivate: [ UserGuard.canActivate ] },
      { path: 'employees/create', component: EmployeeCreateComponent, canActivate: [ UserGuard.canActivate ] },
      { path: 'equipment/create', component: EquipmentCreateComponent, canActivate: [ UserGuard.canActivate ] },
      { path: 'employees/details', component: EmployeeDetailsComponent, canActivate: [ UserGuard.canActivate ] },
      { path: 'equipment/details', component: EquipmentDetailsComponent, canActivate: [ UserGuard.canActivate ] },
      { path: 'login', component: LoginComponent },
      { path: '**', component: EquipmentSearchComponent, canActivate: [ UserGuard.canActivate ] }
    ]
  },
  { path: '**', redirectTo: '/equipment' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
