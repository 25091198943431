import { Injectable } from "@angular/core";
import { Locale } from "./locale";
import { chooseTranslation } from "./translation";
import { TRANSLATIONS } from "./localizations";

export const LOCALE_KEY = 'tracetool.locale';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {
    set(locale: Locale) {
        localStorage.setItem(LOCALE_KEY, locale.toString());
    }

    get(): Locale {
        const value = localStorage.getItem(LOCALE_KEY);
        let locale: Locale;

        switch(value) {
            case 'da':
                locale = Locale.Da;
                break;

            case 'en':
                locale = Locale.En;
                break;

            case 'no':
                locale = Locale.No;
                break;

            default:
                locale = Locale.Da;
                break;
        }

        return locale;
    }

    localize(key: string): string {
        let translation = TRANSLATIONS[key];
        let locale = this.get();
        return chooseTranslation(translation, locale);
    }
}