import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Category } from "src/categories/category";

@Component({
    selector: 'integration',
    templateUrl: 'integration.component.html',
    styleUrls: ['integration.component.scss']
})

export class IntegrationComponent {
    @Input()
    imageUrl: string = '../assets/design/TTThorSplash.png';
    
    @Input()
    title: string = '';
    
    @Input()
    text: string = '';
    
    @Input()
    categories: Category[] = [];

    @Input()
    added: boolean = false;

    @Output()
    addedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    activationToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

    toggle() {
        this.added = !this.added;
        this.addedChange.emit(this.added);
        this.activationToggled.emit(this.added);
    }
}