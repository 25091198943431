import { Component, Input } from "@angular/core";

@Component({
    selector: 'infobox-additional',
    templateUrl: 'infoboxAdditional.component.html',
    styleUrls: ['infoboxAdditional.component.scss']
})

export class InfoboxAdditionalComponent {
    @Input()
    createdDate: string = '';

    @Input()
    createdBy: string = '';

    @Input()
    editedDate: string = '';

    @Input()
    editedBy: string = '';
}