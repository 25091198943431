import { Component, Input, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { from } from "rxjs";
import { EquipmentSize } from "src/equipment/equipment-size";

@Component({
    selector: 'shell',
    templateUrl: 'shell.component.html',
    styleUrls: ['shell.component.scss']
})
export class ShellComponent {
    @Input()
    header!: TemplateRef<any>;

    get EquipmentSize(): typeof EquipmentSize {
        return EquipmentSize;
    }

    constructor(
        private router: Router
    ) { }    

    searchPage(): void {
        from(this.router.navigateByUrl('/equipment')).subscribe();
    }
}