<div class="equipment-details-topbar">
    <icon-button icon="chevron-left"></icon-button>
    <subpage-switch>
        <subpage-switch-button title="Information" [selected]="true"></subpage-switch-button>
        <subpage-switch-button title="Service" (selectedChange)="toggle()"></subpage-switch-button>
    </subpage-switch>
    <icon-text-button icon="right-left" text="Overfør Værktøj"></icon-text-button>
</div>
<div class="equipment-overview">
    <div class="equipment-overview-left">
        <equipment class="equipment-overview-equipment" id="1018" brand="Makita" info="450 W | Variabel Hastighed" [type]="EquipmentSize.Large"></equipment>

    <ng-template #serviceTemplate>
        <attachments
            title="Vedhæftede Filer"
            [attachments]="[
                { url: '1', filename: 'certificate.pdf', displayName: 'Certificate' },
                { url: '2', filename: 'stuff.pdf', displayName: 'Stuff' },
                { url: '3', filename: 'dis.pdf', displayName: 'Dis' },
                { url: '4', filename: 'dat.pdf', displayName: 'Dat' },
            ]">
        </attachments>
    </ng-template>
        
    <ng-container *ngIf="!service; else serviceTemplate">
        <div class="equipment-overview-bottom">
            <div class="equipment-overview-bottom-info">
                <employee class="equipment-overview-bottom-employee" [type]="EmployeeSize.Small" name="Michael Gary Scott" title="Regional Manager"></employee>
                <infobox class="equipment-overview-bottom-purchasedate" icon="calendar-day" title="Indkøbsdato" text="9/12 - 2021"></infobox>
                <infobox class="equipment-overview-bottom-price" icon="tag" title="Indkøbspris" text="2499,95 DKK" [copy]="true"></infobox>
                <infobox class="equipment-overview-bottom-serial" icon="barcode" title="Serienummer" text="4355341213" [copy]="true"></infobox>
            </div>
            <div class="equipment-overview-stats">
                <chart-ring style="width: 350px;" [indicator]="50" text="Udleveret 60% af det sidste år">
                    <chart-ring-part color="red" [weight]="60" text="Udleveret"></chart-ring-part>
                    <chart-ring-part color="green" [weight]="40" text="På Lager"></chart-ring-part>
                </chart-ring>
                <chart-ring style="width: 350px;" [indicator]="10" text="120 dage til næste service">
                    <chart-ring-part color="green" [weight]="50" text="Nyligt Serviceret"></chart-ring-part>
                    <chart-ring-part color="yellow" [weight]="20" text="Serviceret"></chart-ring-part>
                    <chart-ring-part color="red" [weight]="30" text="Skal Serviceres"></chart-ring-part>
                </chart-ring>
            </div>
            <infobox-services class="equipment-overview-upcoming"></infobox-services>
        </div>
    </ng-container>

    </div>

    <div class="equipment-overview-right">
        <log class="equipment-overview-log" title="Aktivitetslog">
            <log-item icon="arrow-left" title="Indlevering" date="23/07 - 2022" text="Indleveret til TraceTool Central"></log-item>
            <log-item title="Kalibrering" date="22/07 - 2022" text="Kalibreret af Dwight Schrute"></log-item>
            <log-item icon="arrow-right" title="Udlevering" date="22/07 - 2022" text="Udleveret fra TraceTool Central"></log-item>
            <log-item icon="arrow-left" title="Indlevering" date="23/07 - 2021" text="Indleveret til TraceTool Central"></log-item>
            <log-item title="Kalibrering" date="22/07 - 2021" text="Kalibreret af Dwight Schrute"></log-item>
            <log-item icon="arrow-right" title="Udlevering" date="22/07 - 2021" text="Udleveret til Dwight Schrute"></log-item>
        </log>
        <attachments class="equipment-overview-attachments"
            title="Vedhæftede Filer"
            [attachments]="[
              { url: '1', filename: 'certificate.pdf', displayName: 'Certificate' },
              { url: '2', filename: 'stuff.pdf', displayName: 'Stuff' },
              { url: '3', filename: 'dis.pdf', displayName: 'Dis' },
              { url: '4', filename: 'dat.pdf', displayName: 'Dat' },
            ]">
          </attachments>
          <infobox-additional class="equipment-overview-additional" createdDate="10/10 - 2022" createdBy="TraceTool Scranton" editedDate="03/04 - 2023" editedBy="Dwight Schrute"></infobox-additional>
    </div>
</div>