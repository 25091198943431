import { NgModule } from "@angular/core";
import { EquipmentComponent } from "./equipment.component";
import { CommonModule } from "@angular/common";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { CategoriesModule } from "src/categories/categories.module";
import { ImagesModule } from "src/images/images.module";
import { ButtonsModule } from "src/components/buttons/buttons.module";
import { ComponentsModule } from "src/components/components.module";
import { AttachmentsModule } from "src/attachments/attachments.module";
import { PaginatorModule } from "src/pagination/paginator.module";
import { InfoBoxModule } from "src/components/infoboxes/infoBox.module";
import { ChartsModule } from "src/components/charts/charts.module";

@NgModule({
    declarations: [
        EquipmentComponent,
    ],
    exports: [
        EquipmentComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeIconsModule,
        CategoriesModule,
        ImagesModule,
        ButtonsModule,
        ComponentsModule,
        AttachmentsModule,
        PaginatorModule,
        InfoBoxModule,
        ChartsModule,
    ]
})
export class EquipmentModule { }