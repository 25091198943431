import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProductItemGroupDto } from "./product-item-group-dto";
import { environment } from "src/environments/environment";
import { ProductItemDto } from "./product-item-dto";
import { ProductItemQuery } from "./product-item-query";
import { PagedQueryResultDto } from "src/pagedQueryResultDto";

export const PRODUCT_ITEM_SERVICE: string = 'PRODUCT_ITEM_SERVICE_TOKEN';

export interface IProductItemService {
    groups(text: string, offset: number, count: number): Observable<ProductItemGroupDto[]>;
    all(query: ProductItemQuery): Observable<PagedQueryResultDto<ProductItemDto>>;
    search(text: string, query: ProductItemQuery): Observable<PagedQueryResultDto<ProductItemDto>>;
}

@Injectable()
export class ProductItemService implements IProductItemService {
    constructor(
        private client: HttpClient
    ) { }

    groups(text: string, offset: number, count: number): Observable<ProductItemGroupDto[]> {
        const url = `${environment.webApiUrl}ProductGroup`;
        const params = { 'text': text, 'offset': offset, 'count': count };
        return this.client.get<ProductItemGroupDto[]>(url, { params: params });
    }

    all(query: ProductItemQuery): Observable<PagedQueryResultDto<ProductItemDto>> {
        const params = query.toParams();
        const url = `${environment.webApiUrl}ProductItems`;
        return this.client.get<PagedQueryResultDto<ProductItemDto>>(url, { params: params });
    }

    search(text: string, query: ProductItemQuery): Observable<PagedQueryResultDto<ProductItemDto>> {
        const params = query.toParams();
        params.text = text;

        const url = `${environment.webApiUrl}ProductItems/Search`;
        return this.client.get<PagedQueryResultDto<ProductItemDto>>(url, { params: params });
    }
}