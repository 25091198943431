import { Component, Input } from "@angular/core";

@Component({
    selector: 'checkbox-text',
    templateUrl: 'checkboxText.component.html',
    styleUrls: ['checkboxText.component.scss']
})

export class CheckboxTextComponent {
    @Input()
    text: string = '';
}