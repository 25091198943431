<div class="input">
    <label for="title" class="input-title">{{title}} *</label>
    <input 
        #input
        [type]="type.toString()" 
        class="input-text" 
        placeholder="{{placeholder}}" 
        [required]="required"
        [pattern]="type === InputType.Date ? '\d{4}-\d{2}-\d{2}' : undefined"
        [value]="value"
        (change)="valueChange.emit(input.value)"
        [readOnly]="readonly">
</div>