import { Component, Input } from "@angular/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { iconFor, extensionOf } from "./extensions";

@Component({
    selector: 'attachment',
    templateUrl: 'attachment.component.html',
    styleUrls: ['attachment.component.scss']
})
export class AttachmentComponent {
    @Input()
    filename: string = '';

    @Input()
    displayName: string = '';

    @Input()
    url: string = '';

    icon(): IconProp {
        const extension = extensionOf(this.filename);

        if(extension === null)
            return 'arrow-up-right-from-square';

        return iconFor(extension);        
    }

    open() {
        if(this.url === '')
            return;

        window.open(this.url);
    }
}