<div class="container">
    <img src="../assets/design/TTThorSplash.png">
    <form class="login-form">
        <h1 class="login-form-title">LOGIN</h1>
        <div class="login-mode-toggle" (click)="toggleCompanyLogin()">
            <button [class]="isCompanyLogin ? 'left' : 'left active'">Bruger</button>
            <button [class]="isCompanyLogin ? 'right active' : 'right'" class="right">Firma</button>
        </div>
        <div class="login-form-input">
            <input 
                name="username"
                [(ngModel)]="username"
                type="text" 
                class="login-form-entry" 
                [placeholder]="isCompanyLogin ? 'Employee #' : 'Username'" 
                required autofocus>

            <input 
                name="password"
                [(ngModel)]="password"
                type="password" 
                class="login-form-entry" 
                placeholder="Password" 
                required>

            <input 
                *ngIf="isCompanyLogin" 
                name="companyId"
                [(ngModel)]="companyId"
                type="text" 
                class="login-form-entry" 
                placeholder="Company ID" 
                required>
        </div>
        <label class="check-password">
            Husk Kodeord
            <input type="checkbox" [(ngModel)]="rememberLogin" [ngModelOptions]="{standalone: true}">
            <span class="check-password_mark"></span>
        </label>
        <text-button text="Log Ind" (click)="login()"></text-button>
        <icon-double-text-button icon="fingerprint" title="Kommer Snart" text="EG ID" [disabled]="true"></icon-double-text-button>
    </form>
</div>