import { Image } from "./image"

export interface PhotoDto {
    id: string,
    url: string,
    isDefault: boolean
}

export function photoToImage(photo: PhotoDto): Image {
    return new Image(photo.id, photo.url, photo.isDefault);
}