import { AfterContentInit, Component, Input, Output } from "@angular/core";
import { GallerySize } from "./image-gallery-size";
import { Image } from "./image";
import { clamp } from "src/math";
import { BehaviorSubject } from "rxjs";
import { Animation } from 'src/animation';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

@Component({
    selector: 'image-gallery',
    templateUrl: 'imageGallery.component.html',
    styleUrls: ['imageGallery.component.scss']
})
export class ImageGalleryComponent implements AfterContentInit {
  private _images: Image[] = [];
  
  @Input()
  get images(): Image[] {
    return this._images;
  }

  set images(value: Image[]) {
    this._images = value;
    this.select(0);
  }

  @Output()
  selected: BehaviorSubject<Image | null>;
  
  @Input()
  type: GallerySize;
  
  @Input()
  defaultIcon: IconProp = 'user';

  get GallerySize(): typeof GallerySize {
    return GallerySize;
  }
  
  selectedIndex: number;
  fadeAnimation: Animation;

  constructor() {
    this.type  = GallerySize.Large;
    this.selectedIndex = 0;
    this.fadeAnimation = new Animation(0.05);
    this.selected = new BehaviorSubject<Image | null>(null);
  }
  
  ngAfterContentInit(): void {
    this.select(0);
  }

  next() {
    this.select(this.selectedIndex + 1);
  }

  previous() {
    this.select(this.selectedIndex - 1);
  }

  select(index: number) {
    this.selectedIndex = clamp(0, this.images.length - 1, index);
    this.selected.next(this.images[this.selectedIndex]);
    this.fadeAnimation.restart();
  }
}