<div class="paginator">
    <ng-container class="paginator-content" *ngTemplateOutlet="selected.template"></ng-container>

    <div class="paginator-footer">
        <fa-icon class="arrow" icon="chevron-left" (click)="previous()"></fa-icon>
        
        <span 
            *ngFor="let p of pages; index as i" 
            [class]="classFor(i)" 
            (click)="showPage(i)">
            {{i + 1}}
        </span>

        <fa-icon class="arrow" icon="chevron-right" (click)="next()"></fa-icon>
    </div>
</div>