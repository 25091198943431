import { Component, Input } from "@angular/core";
import { XenaIntegrationState } from "./xena-integration-state";
import { Integration } from "./integration";

@Component({
    selector: 'xena-integration',
    templateUrl: 'xena-integration.component.html'
})
export class XenaIntegrationComponent {
    @Input()
    state!: Integration<XenaIntegrationState>;
}