import { Component, EventEmitter, Output } from "@angular/core";
import { Subject, firstValueFrom } from "rxjs";

@Component({
    selector: 'attachment-add',
    templateUrl: 'attachment-add.component.html',
    styleUrls: [ 'attachment-add.component.scss' ]
})
export class AttachmentAddComponent {
    @Output()
    added: EventEmitter<File> = new EventEmitter<File>();
    
    async add(): Promise<File | null> {
        let emitter = new Subject<File | null>();
        
        const fileDialog = document.createElement('input');
        fileDialog.type = 'file';

        fileDialog.addEventListener('change', () => {
            let file = fileDialog.files?.item(0) ?? null;

            if(file !== null)
                this.added.emit(file);
            
            emitter.next(file);
        });

        fileDialog.click();
        return await firstValueFrom(emitter);
    }
}