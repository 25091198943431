import { Component } from "@angular/core";
import { InputType } from "src/components/create/createInput.component";
import { EquipmentSize } from "../equipment-size";
import { GallerySize } from "src/images/image-gallery-size";
import { ProductItem } from "../product-item";

@Component({
    templateUrl: './equipment-create.component.html',
    styleUrls: ['./equipment-create.component.scss']
})

export class EquipmentCreateComponent {
    suggestions: ProductItem[] = [];

    get InputType(): typeof InputType {
        return InputType;
    }

    get EquipmentSize(): typeof EquipmentSize {
        return EquipmentSize;
    }

    get GallerySize(): typeof GallerySize {
        return GallerySize;
    }

    constructor() {
        const item = new ProductItem();
        item.brand = 'Makita';
        item.info = '450 W | Variabel Hastighed';
        item.categories.push({ id: '', name: 'Boremaskine' });
        this.suggestions.push(item);
    }
}