import { Component, EventEmitter, Input, Output } from "@angular/core";

export enum InputType {
    Text = 'text',
    Number = 'number',
    Email = 'email',
    Phone = 'phone',
    Date = 'date',
}

@Component({
    selector: 'create-input',
    templateUrl: 'createInput.component.html',
    styleUrls: ['createInput.component.scss']
})

export class CreateInputComponent {
    @Input()
    title: string = '';

    @Input()
    placeholder: string = '';

    @Input()
    type: InputType = InputType.Text;

    @Input()
    required: boolean = true;

    @Input()
    value: any = '';

    @Output()
    valueChange: EventEmitter<any> = new EventEmitter<any>();

    @Input()
    readonly: boolean = false;

    get InputType(): typeof InputType {
        return InputType;
    }

    onValueChanged(value: any) {
        this.value = value;
        this.valueChange.emit(value);
    }
}