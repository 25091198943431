import { Component, ContentChildren, QueryList } from "@angular/core";
import { PaginatorPageComponent } from "./paginator-page.component";

@Component({
    selector: 'paginator',
    templateUrl: 'paginator.component.html',
    styleUrls: ['paginator.component.scss']
})
export class PaginatorComponent {
    get selected(): PaginatorPageComponent {
        return this.pages?.get(this.pageIndex) ?? new PaginatorPageComponent();
    }

    @ContentChildren(PaginatorPageComponent, { descendants: false })
    pages: QueryList<PaginatorPageComponent> | null = null;

    pageIndex: number = 0;

    showPage(page: number) {
        this.trySetPage(page);
    }

    next() {
        const newPage = this.pageIndex + 1;
        this.trySetPage(newPage);
    }

    previous() {
        const newPage = this.pageIndex - 1;
        this.trySetPage(newPage);
    }

    trySetPage(index: number) {
        const MIN = 0;
        const MAX = (this.pages?.length ?? 1) - 1;
        this.pageIndex = Math.max(Math.min(index, MAX), MIN);
    }

    classFor(index: number): string {
        const INACTIVE_CLASS = 'paginator-footer-item';;
        const ACTIVE_CLASS = 'paginator-footer-item active';
        
        return index == this.pageIndex 
            ? ACTIVE_CLASS 
            : INACTIVE_CLASS;
    }
}