import { Component, Input } from "@angular/core";
import { KeatechIntegrationState } from "./keatech-integration-state";
import { Integration } from "./integration";

@Component({
    selector: 'keatech-integration',
    templateUrl: 'keatech-integration.component.html'
})
export class KeatechIntegrationComponent {
    @Input()
    state!: Integration<KeatechIntegrationState>;
}