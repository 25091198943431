<div class="content">
    <ng-content></ng-content>
</div>
<div class="shell">
    <div class="top-bar">
        <img src="../assets/design/EGTT.png" class="top-bar-logo" (click)="searchPage()">
        <div class="header">
            <ng-container [ngTemplateOutlet]="header"></ng-container>
        </div>
        <user-panel></user-panel>
    </div>
    <div class="sidemenu">
        <div class="sidemenu-item" (click)="searchPage()">
            <a>Søgeside</a>
        </div>
        <div class="sidemenu-item">
            <a>Mit Værktøj</a>
            <counter></counter>
        </div>
        <div class="sidemenu-bottom">
            <p class="sidemenu-bottom-title">Værktøjsliste</p>
            <div class="sidemenu-bottom-list">
                <equipment [type]="EquipmentSize.Mini" brand="Makita" info="450 W | Variabel Hastighed"></equipment>
                <equipment [type]="EquipmentSize.Mini" brand="Makita" info="400 W | Variabel Hastighedhjgkeahkjgahgkj"></equipment>
            </div>
            <div class="sidemenu-bottom-dragdrop">
                <fa-icon icon="clipboard-list"></fa-icon>
                <p>Træk værktøj hertil for at tilføje til udvalgsliste</p>
            </div>
            <icon-text-button icon="right-left" text="Overførsel"></icon-text-button>
        </div>
    </div>
</div>