<div class="dropdown">
    <label *ngIf="title !== ''" for="title" class="dropdown-title">{{title}} *</label>
    <button dropdown-menu-trigger [menu]="dropdownMenu" class="dropdown-btn">
      <fa-icon [icon]="(selectedOption | async)?.icon ?? 'question'" [class]="(selectedOption | async)?.colorClass() ?? 'dropdown-icon'"></fa-icon>
      <span class="dropdown-text">{{(selectedOption | async)?.text}}</span>
      <fa-icon icon="caret-down" class="dropdown-arrow"></fa-icon>
    </button>
    <div #dropdownMenu class="dropdown-menu show">
        <a *ngFor="let option of options" class="dropdown-menu-item" (click)="this.selected = option?.value">
            <fa-icon [icon]="option.icon" [class]="option.colorClass()"></fa-icon>
            <span class="dropdown-menu-text">{{option.text}}</span>
        </a>
    </div>
</div>
