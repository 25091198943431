import { Activity } from "src/activity";
import { EmployeeDto } from "./employeeDto";
import { fromJavascriptDateTime, millisecondsToDays } from "src/dates";

export const DaysBeforeConsideredInactive: number = 30;

export class Employee {
    public id: string = '';
    public publicId: string = '';
    public name: string = '';
    public title: string = '';
    public department: string = '';
    public imageUrl: string = '';
    public lastActivity: Date = new Date(Date.now());
    
    activitySince(date: number): Activity {
        const diff = date - this.lastActivity.getTime();
        const diffDays = millisecondsToDays(diff);
        return diffDays < DaysBeforeConsideredInactive
            ? Activity.Active
            : Activity.Inactive;
    }

    static from(dto: EmployeeDto): Employee {
        const employee = new Employee();
        employee.id = dto.id;
        employee.publicId = dto.publicId;
        employee.name = dto.name;
        employee.title = dto.title;
        employee.department = dto.department;
        employee.imageUrl = dto.thumbnailUrl;
        employee.lastActivity = fromJavascriptDateTime(dto.lastActivity);
        return employee;
    }
}