import { NgModule } from "@angular/core";
import { EmployeeComponent } from "./employee.component";
import { CommonModule } from "@angular/common";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { EmployeeCreateComponent } from "./employee-create.component";
import { ComponentsModule } from "src/components/components.module";
import { ButtonsModule } from "src/components/buttons/buttons.module";
import { ImagesModule } from "src/images/images.module";
import { AttachmentsModule } from "src/attachments/attachments.module";
import { RolesModule } from "src/roles/roles.module";
import { EmployeeDetailsComponent } from "./employee-details.component";
import { InfoBoxModule } from "src/components/infoboxes/infoBox.module";
import { EquipmentModule } from "src/equipment/equipment.module";
import { PaginatorModule } from "src/pagination/paginator.module";
import { LayoutModule } from "src/layout/layout.module";
import { InfoBoxServicesModule } from "src/components/infoboxes/services/infoboxServices.module";

@NgModule({
    declarations: [
        EmployeeComponent,
        EmployeeCreateComponent,
        EmployeeDetailsComponent,
    ],
    exports: [
        EmployeeComponent,
        EmployeeCreateComponent,
        EmployeeDetailsComponent,
    ],
    imports: [
        CommonModule,
        FontAwesomeIconsModule,
        ComponentsModule,
        ButtonsModule,
        ImagesModule,
        AttachmentsModule,
        RolesModule,
        InfoBoxModule,
        EquipmentModule,
        PaginatorModule,
        LayoutModule,
        InfoBoxServicesModule,
    ]
})
export class EmployeesModule { }