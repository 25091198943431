export class Vector2 {
    constructor(
        public x: number,
        public y: number
    ) { }

    sub(other: Vector2): Vector2 {
        return new Vector2(
            this.x - other.x,
            this.y - other.y
        );
    }

    add(other: Vector2): Vector2 {
        return new Vector2(
            this.x + other.x,
            this.y + other.y
        );
    }

    scale(value: number) {
        return new Vector2(
            this.x * value,
            this.y * value
        );
    }

    angle(): number {
        return Math.atan2(this.y, this.x);
    }
}