import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserDto } from "./user-dto";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(
        private client: HttpClient
    ) { }

    get(): Observable<UserDto> {
        const url = `${environment.webApiUrl}Users/Current`;
        return this.client.get<UserDto>(url);
    }
}