

<div class="library" id="top">
  <div class="library-title">
    <fa-icon icon="book"></fa-icon>
    <span class="library-title-text">Library</span>
  </div>

  <div class="library-grid">
    
    <!-- 1. BUTTONS -->
    <div class="library-grid-item">
      <a class="library-grid-item-title" href="/library#buttons">
        <fa-icon icon="play"></fa-icon>
        <span class="library-grid-item-title-text">Buttons</span>
      </a>
      <ul class="library-grid-item-list">
        <a class="library-grid-item-list-link" href="/library#iconButtons">Icon Buttons</a><br>
        <a class="library-grid-item-list-link" href="/library#iconTextButtons">Icon Text Buttons</a><br>
        <a class="library-grid-item-list-link" href="/library#iconDoubleTextButtons">Icon Double Text Buttons</a><br>
        <a class="library-grid-item-list-link" href="/library#textButtons">Text Buttons</a>
      </ul>
    </div>

    <!-- 2. COLLAPSIBLES -->
    <div class="library-grid-item">
      <a class="library-grid-item-title" href="/library#collapsibles">
        <fa-icon icon="up-down"></fa-icon>
        <span class="library-grid-item-title-text">Collapsibles</span>
      </a>
      <ul class="library-grid-item-list">
        <a class="library-grid-item-list-link" href="/library#collapsiblesGrid">Collapsible Grids</a><br>
        <a class="library-grid-item-list-link" href="/library#collapsiblesLog">Collapsible Log Entry</a>
      </ul>
    </div>

    <!-- 3. CREATE (admin) -->
    <div class="library-grid-item">
      <a class="library-grid-item-title" href="/library#create">
        <fa-icon icon="circle-plus"></fa-icon>
        <span class="library-grid-item-title-text">Creation Components</span>
      </a>
      <ul class="library-grid-item-list">
        <a class="library-grid-item-list-link" href="/library#createInput">Inputs</a><br>
        <a class="library-grid-item-list-link" href="/library#createRoles">Roles</a><br>
        <a class="library-grid-item-list-link" href="/library#createService">Service</a><br>
        <a class="library-grid-item-list-link" href="/library#addImages">Images</a>
      </ul>
    </div>

    <!-- 4. DROPDOWNS -->
    <div class="library-grid-item">
      <a class="library-grid-item-title" href="/library#dropdowns">
        <fa-icon icon="square-caret-down"></fa-icon>
        <span class="library-grid-item-title-text">Dropdowns</span>
      </a>
      <ul class="library-grid-item-list">
        <a class="library-grid-item-list-link" href="/library#dropdown">Dropdown</a><br>
        <a class="library-grid-item-list-link" href="/library#dropdownOptions">Dropdown Options</a><br>
        <a class="library-grid-item-list-link" href="/library#categoryList">Category List</a>
      </ul>
    </div>

    <!-- 5. ELEMENTS -->
    <div class="library-grid-item">
      <a class="library-grid-item-title" href="/library#elements">
        <fa-icon icon="puzzle-piece"></fa-icon>
        <span class="library-grid-item-title-text">Elements</span>
      </a>
      <ul class="library-grid-item-list">
        <a class="library-grid-item-list-link" href="/library#employee">Employee</a><br>
        <a class="library-grid-item-list-link" href="/library#equipment">Equipment</a><br>
        <a class="library-grid-item-list-link" href="/library#integration">Integration</a>
      </ul>
    </div>

    <!-- 6. INFO BOXES -->
    <div class="library-grid-item">
      <a class="library-grid-item-title" href="/library#infoBoxes">
        <fa-icon icon="circle-info"></fa-icon>
        <span class="library-grid-item-title-text">Info Boxes</span>
      </a>
      <ul class="library-grid-item-list">
        <a class="library-grid-item-list-link" href="/library#infoBox">Info Box</a><br>
        <a class="library-grid-item-list-link" href="/library#infoBoxAdditional">Additional Info Box</a><br>
        <a class="library-grid-item-list-link" href="/library#infoBoxServices">Services</a>
      </ul>
    </div>

    <!-- 7. PAGINATION -->
    <div class="library-grid-item">
      <a class="library-grid-item-title">
        <fa-icon icon="list-ol"></fa-icon>
        <span class="library-grid-item-title-text">Pagination</span>
      </a>
      <ul class="library-grid-item-list">
        <a class="library-grid-item-list-link" href="/library#paginator">Paginator</a><br>
        <a class="library-grid-item-list-link" href="/library#paginatorPage">Paginator Pages</a><br>
        <a class="library-grid-item-list-link" href="/library#subpageSwitch">Subpage Switch</a><br>
        <a class="library-grid-item-list-link" href="/library#subpageSwitchButton">Subpage Switch Button</a>
      </ul>
    </div>

    <!-- 8. TRACKERS -->
    <div class="library-grid-item">
      <a class="library-grid-item-title" href="/library#trackers">
        <fa-icon icon="chart-column"></fa-icon>
        <span class="library-grid-item-title-text">Trackers</span>
      </a>
      <ul class="library-grid-item-list">
        <a class="library-grid-item-list-link" href="library/#serviceTracker">Service Tracker</a><br>
        <a class="library-grid-item-list-link" href="/library#pieCharts">Pie Charts</a>
      </ul>
    </div>

    <!-- 9. COMPONENTS -->
    <div class="library-grid-item">
      <a class="library-grid-item-title" href="/library#components">
        <fa-icon icon="object-group"></fa-icon>
        <span class="library-grid-item-title-text">Various Components</span>
      </a>
      <ul class="library-grid-item-list">
        <a class="library-grid-item-list-link" href="/library#attachments">Attachments</a><br>
        <a class="library-grid-item-list-link" href="/library#categoryChips">Category Chips</a><br>
        <a class="library-grid-item-list-link" href="/library#checkBox">Checkbox w/ Text</a><br>
        <a class="library-grid-item-list-link" href="/library#commentFields">Comment Fields</a><br>
        <a class="library-grid-item-list-link" href="/library#counter">Counter</a><br>
        <a class="library-grid-item-list-link" href="/library#imageGallery">Image Galleries</a><br>
        <a class="library-grid-item-list-link" href="/library#serviceLog">Service Log</a><br>
        <a class="library-grid-item-list-link" href="/library#searchBar">Search Bar</a>
      </ul>
    </div>
  </div>

  <div class="content">

    <!-- 1. BUTTONS SECTION -->
    <div class="content-container" id="buttons">
      <div class="content-title">
        <fa-icon icon="play"></fa-icon>
        <span class="content-title-text">Buttons</span>
      </div>

      <div class="content-section-container">

        <div class="content-section" id="iconButtons">
          <span class="content-section-subtitle">Icon Buttons</span>
          <p class="content-section-tag">&lt;icon-button&gt;</p>
          <div style="display: flex; align-items: center; gap: 20px;">
            <icon-button icon="lightbulb"></icon-button>
            <icon-button icon="lightbulb" color="red" shape="square"></icon-button>
          </div>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>icon</b> -
                Define a Font Awesome icon using 'Icon Name'
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>color</b> -
                Changes the color of the button. The options here being between the default of blue, and red (which is reserved for deletion/cancellation options)
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>shape</b> -
                Changes the shape of the button between the default of <em>round</em> and a <em>square</em> design. 
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="iconTextButtons">
          <span class="content-section-subtitle">Icon Text Buttons</span>
          <p class="content-section-tag">&lt;icon-text-button&gt;</p>
          <div style="display: flex; align-items: center; gap: 20px;">
            <icon-text-button icon="hammer" text="Equipment"></icon-text-button>
            <icon-text-button icon="user" text="Employee" color="red" shape="square"></icon-text-button>
          </div>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>icon</b> -
                Define a Font Awesome icon using 'Icon Name'
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Inserts one line of text for a button
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>color</b> -
                Changes the color of the button. The options here being between the default of blue, and red (which is reserved for deletion/cancellation options)
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>shape</b> -
                Changes the shape of the button between the default of <em>round</em> and a <em>square</em> design. 
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[disabled]</b> -
                A boolean value that decides whether or not the button is disabled or not.
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="iconDoubleTextButtons">
          <span class="content-section-subtitle">Icon Multi-line Text Buttons</span>
          <p class="content-section-tag">&lt;icon-double-text-button&gt;</p>
          <icon-double-text-button icon="fingerprint" title="EG ID" text="Coming soon.."></icon-double-text-button>
          <p class="content-section-text">Should <em>NOT</em> be used as a regular <a href="/library#iconTextButtons">Icon Text Button</a></p>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>icon</b> -
                Define a Font Awesome icon using 'Icon Name'
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Inserts the upper line of title text for a button
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Inserts the lower line of text for a button
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[disabled]</b> -
                A boolean value that decides whether or not the button is disabled or not.
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="textButtons">
          <span class="content-section-subtitle">Text Buttons</span>
          <p class="content-section-tag">&lt;text-button&gt;</p>
          <text-button text="Log Ind"></text-button>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Inserts one line of text for a button
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[disabled]</b> -
                A boolean value that decides whether or not the button is disabled or not.
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 2. COLLAPSIBLES SECTION -->
    <div class="content-container" id="collapsibles">
      <div class="content-title">
        <fa-icon icon="up-down"></fa-icon>
        <span class="content-title-text">Collapsibles</span>
      </div>

      <div class="content-section-container">

        <div class="content-section" id="collapsiblesGrid">
          <span class="content-section-subtitle">Collapsible Grids</span>
          <p class="content-section-tag">&lt;collapsible-grid&gt;</p>
          <collapsible-grid title="Kalibrering">
            <grid-item text="1 år"></grid-item>
            <grid-item text="6 mdr"></grid-item>
            <grid-item text="3 mdr"></grid-item>
          </collapsible-grid>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Inserts a title within a single collapsible grid panel
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>subtitle</b> -
                Sets the title for each element within the collapsible grid
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Sets the descriptive larger part text of the collapsible grid
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="collapsiblesLog">
          <span class="content-section-subtitle">Collapsible Log Entry</span>
          <p class="content-section-tag">&lt;log&gt;</p>
          <p class="content-section-text">More info can be found about the entire component in the <a href="/library#serviceLog">Service Log</a> section</p>
          <log>
            <log-item title="Kalibrering" date="22/07 - 2022" text="Kalibreret af Dwight Schrute"></log-item>
            <log-item icon="hammer" title="Kalibrering" date="22/07 - 2022" text="Kalibreret af Dwight Schrute"></log-item>
            <log-item title="Kalibrering" date="22/07 - 2022" text="Kalibreret af Dwight Schrute"></log-item>
          </log>
        </div>

      </div>

    </div>

    <!-- 3. CREATE (admin) SECTION -->
    <div class="content-container" id="create">
      <div class="content-title">
        <fa-icon icon="circle-plus"></fa-icon>
        <span class="content-title-text">Create (admin)</span>
      </div>

      <div class="content-section-container">

        <div class="content-section" id="createInput">
          <span class="content-section-subtitle">Create Input Fields</span>
          <p class="content-section-tag">&lt;create-input&gt;</p>
          <create-input title="Input Field" placeholder="placeholder text.." [required]="true"></create-input>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Inserts a title for either text input fields, required or not, or number fields
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>placeholder</b> -
                Defines the placeholder text for a text input, required or not, or a number field.
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>required</b> -
                Needs a boolean value to decide whether the input field is required or not, and will style accordingly. This feature is reserved for text input fields only, as other types are preset as required or not.
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[type]</b> -
                Changes the type of input fields by using <b>InputType.</b> followed by <em>Text, Number, Email, Phone or Date</em>, which will change the restrictions of what is typed into the field (and in the case of Email, Phone or Date the title is also set by the type). Default is set to <b>InputType.Text</b>
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="createRoles">
          <span class="content-section-subtitle">Create Roles</span>
          <p class="content-section-tag">&lt;create-role&gt;</p>
          <p class="content-section-text">&lt;create-role-feature&gt;'s <b>text</b> attribute defines a feature that the role would have access to, such as Service, Transfer etc. This can be set multiple times per element as individual tags and will simply expand the features list to a maximum of 3 points. </p>
          <create-role icon="user" title="Medarbejder" [amount]="9" [maxAmount]="25" text="Basis adgang til TraceTool Thor" price="50">
            <create-role-feature text="Service"></create-role-feature>
            <create-role-feature text="Transfer"></create-role-feature>
            <create-role-feature text="Profile"></create-role-feature>
          </create-role>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>icon</b> -
                Sets the icon for the individual role creator element
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[amount]</b> -
                Sets the current amount of users within this role for a specific company
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[maxAmount]</b> -
                Sets the limit for amount of users the specific role within a company
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Describes the title of a role creator element, such as Employee or Administrator
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Inserts a short but descriptive string of text explaining the specific role
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>price</b> -
                Defines the price per user of this role within a company. This is to be a number ONLY value.
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="createService">
          <span class="content-section-subtitle">Create Service</span>
          <p class="content-section-tag">&lt;create-service&gt;</p>
          <p class="content-section-text">A side element with several collapsible grids that lets the user pick one or more default services to set for the equipment upon creation</p>
          <create-service></create-service>
        </div>

        <div class="content-section" id="addImages">
          <span class="content-section-subtitle">Add Images</span>
          <p class="content-section-tag">&lt;add-images&gt;</p>
          <p class="content-section-text">An element that allows the user to add, rotate or delete one or more images to an Employee or a piece of equipment</p>
          <add-images ownerId="8fc9fadb-a163-4310-a6d2-0661bed8fd12"></add-images>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>ownerId</b> -
                The owner of the images's id. Used to fetch and update images from the backend.
              </p>
            </div>
          </div>
        </div>

      </div>

    </div>

    <!-- 4. DROPDOWNS SECTION -->
    <div class="content-container" id="dropdowns">
      <div class="content-title">
        <fa-icon icon="square-caret-down"></fa-icon>
        <span class="content-title-text">Dropdowns</span>
      </div>

      <div class="content-section-container">

        <div class="content-section" id="dropdown">
          <span class="content-section-subtitle">Dropdown</span>
          <p class="content-section-tag">&lt;dropdown&gt;</p>
          <p class="content-section-text">Functions as a parent element to <a href="/library#dropdownOptions">&lt;dropdown-option&gt;</a></p>
          <dropdown title="Afdeling">
            <dropdown-option icon="warehouse" text="TraceTool Central" iconColor="green"></dropdown-option>
            <dropdown-option icon="warehouse" text="TraceTool East" iconColor="grey"></dropdown-option>
            <dropdown-option icon="warehouse" text="TraceTool South" iconColor="grey"></dropdown-option>
          </dropdown>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Only used to insert a string of text to use for required titles to appear on top of dropdowns
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="dropdownOptions">
          <span class="content-section-subtitle">Dropdown Options</span>
          <p class="content-section-tag">&lt;dropdown-option&gt;</p>
          <p class="content-section-text">Functions as a child element to <a href="/library#dropdown">&lt;dropdown&gt;</a> and can be used multiple times within a single parent element</p>
          <dropdown>
            <dropdown-option icon="wrench" text="Serviceret"></dropdown-option>
            <dropdown-option icon="screwdriver" text="Reparation" iconColor="yellow"></dropdown-option>
            <dropdown-option icon="triangle-exclamation" text="Overskredet Service" iconColor="red"></dropdown-option>
          </dropdown>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>icon</b> -
                Define a Font Awesome icon using 'Icon Name' for a single dropdown option
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Inserts one line of text for a single dropdown option
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>iconColor</b> -
                Changes the color of the icon attribute. The options are <em>green, red, yellow</em> or <em>grey</em>
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="categoryList">
          <span class="content-section-subtitle">Category list</span>
          <p class="content-section-tag">&lt;category-list&gt;</p>
          <p class="content-section-text">Used to add or remove categories from products or modules.</p>
          <category-list [categories]="[{ id: '123', name: 'borehammer' }, { id: '321', name: 'boremaskine' }, { id: '1234', name: 'søm' }]">
          </category-list>
        </div>

      </div>
    </div>

    <!-- 5. ELEMENTS SECTION -->
    <div class="content-container" id="elements">
      <div class="content-title">
        <fa-icon icon="puzzle-piece"></fa-icon>
        <span class="content-title-text">Elements</span>
      </div>

      <div class="content-section-container">

        <div class="content-section" id="employee">
          <span class="content-section-subtitle">Employee</span>
          <p class="content-section-tag">&lt;employee&gt;</p>
          <employee id="007" name="Michael Gary Scott" title="Regional Manager" department="TraceTool Scranton" [type]="EmployeeSize.Large"></employee>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>id</b> -
                Inserts an ID number value of an employee
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>name</b> -
                Inserts the name of an employee
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Inserts the title of an employee
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>department</b> -
                Inserts the department name that an employee is assigned
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>icon</b> -
                Define a Font Awesome icon using 'Icon Name'. Is set by Default
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[type]</b> -
                Switches the size type of an &lt;employee&gt; element using <b>EmployeeSize.</b> followed by Large, Medium, Small or Mini. <br>Default is set to <em>EmployeeSize.Medium</em>.
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="equipment">
          <span class="content-section-subtitle">Equipment</span>
          <p class="content-section-tag">&lt;equipment&gt;</p>
          <equipment 
            id="1028" 
            brand="Makita" 
            info="480 V | Variabel Hastighed" 
            [type]="EquipmentSize.Large"
            [categories]="[
              { id: '1', name: 'borehammer' },
              { id: '2', name: 'hammer' },
              { id: '3', name: 'søm' },
              { id: '4', name: 'boremasking' },
              { id: '5', name: 'svensknøgle' }
            ]">
          </equipment>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>id</b> -
                Inserts an ID number value of a piece of equipment
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>brand</b> -
                Inserts the brand assigned to a piece of equipment
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>info</b> -
                Inserts the information tied to a piece of equipment
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>categories</b> -
                Sets the &lt;category-chip&gt; assigned to a piece of equipment
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[type]</b> -
                Switches the size type of an &lt;equipment&gt; element using <b>EquipmentSize.</b> followed by Large, Medium, Small, Mini or Create (only used for Equipment Suggestions upon Creation). Default is set to <em>EquipmentSize.Medium</em>.
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="integration">
          <span class="content-section-subtitle">Integration</span>
          <p class="content-section-tag">&lt;integration&gt;</p>
          <integration title="EG TraceTool Season Pass" text="Køb dig adgang til grundspillet og få et antal af 3 forskellige in game currencies som lige præcis ikke er nok til at købe noget brugbart i vores in-game shop. Stay tuned for Year 1 Season Pass!"></integration>
          <p class="content-section-text">An element to enable/disable specific TraceTool integrations. To be used only on the integrations subpage</p>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Defines the title text of the specific integration
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Inserts a string of text with the description for the individual integration component
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- 6. INFO BOXES SECTION -->
    <div class="content-container" id="infoBoxes">
      <div class="content-title">
        <fa-icon icon="circle-info"></fa-icon>
        <span class="content-title-text">Info Boxes</span>
      </div>

      <div class="content-section-container">

        <div class="content-section" id="infoBox">
          <span class="content-section-subtitle">Info Box</span>
          <p class="content-section-tag">&lt;infobox&gt;</p>
          <infobox icon="hammer" title="Antal Værktøj" text="420" [copy]="true"></infobox>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>icon</b> -
                Define a Font Awesome icon using 'Icon Name'
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Sets the inner title of an info box element
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Inserts the informational text within an info box element
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[copy]</b> -
                A boolean value that decides whether or not the text field in the infobox is copyable or not. Additionally, this will add the copy icon to the infobox.
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="infoBoxAdditional">
          <span class="content-section-subtitle">Additional Info Box</span>
          <p class="content-section-tag">&lt;infobox-additional&gt;</p>
          <infobox-additional></infobox-additional>
          <p class="content-section-text">Contains information about when an Employee or Equipment page was created and last edited, and by who</p>
        </div>

        <div class="content-section" id="infoBoxServices">
          <span class="content-section-subtitle">Services Info Box</span>
          <p class="content-section-tag">&lt;infobox-services&gt;</p>
          <infobox-services></infobox-services>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>serviceType</b> -
                Defines the type of service that currently shows the closest upcoming service task of a piece of equipment
              </p>
            </div>
          </div>
        </div>

      </div>

    </div>

    <!-- 7. PAGINATION -->
    <div class="content-container" id="trackers">
      <div class="content-title">
        <fa-icon icon="chart-column"></fa-icon>
        <span class="content-title-text">Pagination</span>
      </div>

      <div class="content-section-container">
        <div class="content-section" id="paginator">
          <span class="content-section-subtitle">Paginator</span>
          <p class="content-section-tag">&lt;paginator&gt;</p>
          <paginator>
            <paginator-page>
              <ng-template>
                <span style="margin: 0px auto 0px auto; color: var(--tdarkblue); font-weight: 600;">Page 1</span>
              </ng-template>
            </paginator-page>
            <paginator-page>
              <ng-template>
                <span style="margin: 0px auto 0px auto; color: var(--tdarkblue); font-weight: 600;">Page 2</span>
              </ng-template>
            </paginator-page>
            <paginator-page>
              <ng-template>
                <span style="margin: 0px auto 0px auto; color: var(--tdarkblue); font-weight: 600;">Page 3</span>
              </ng-template>
            </paginator-page>
          </paginator>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>content</b> -
                List of &lt;paginator-page&gt; elements to be shown.
              </p>
            </div>
          </div>
        </div>
        <div class="content-section" id="paginatorPage">
          <span class="content-section-subtitle">Paginator Page</span>
          <p class="content-section-tag">&lt;paginator-page&gt;</p>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>content</b> -
                A &lt;ng-template&gt; containing the content of the page.
              </p>
            </div>
          </div>
        </div>
        <div class="content-section" id="subpageSwitch">
          <span class="content-section-subtitle">Subpage Switch</span>
          <p class="content-section-tag">&lt;subpage-switch&gt;</p>
          <subpage-switch>
            <subpage-switch-button title="Description" [selected]="true"></subpage-switch-button>
            <subpage-switch-button title="Services"></subpage-switch-button>
            <subpage-switch-button title="Manufacturers"></subpage-switch-button>
          </subpage-switch>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>content</b> -
                A list of &lt;subpage-switch-button&gt;
              </p>
            </div>
          </div>
        </div>
        <div class="content-section" id="subpageSwitchButton">
          <span class="content-section-subtitle">Subpage Switch Button</span>
          <p class="content-section-tag">&lt;subpage-switch-button&gt;</p>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Text shown on the button.
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>selected</b> -
                True if its the currently selected button.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 8. TRACKERS SECTION -->
    <div class="content-container" id="trackers">
      <div class="content-title">
        <fa-icon icon="chart-column"></fa-icon>
        <span class="content-title-text">Trackers</span>
      </div>

      <div class="content-section-container">

        <div class="content-section" id="serviceTracker">
          <span class="content-section-subtitle">Service Tracker</span>
          <p class="content-section-tag">&lt;service-tracker&gt;</p>
          <service-tracker 
            icon="screwdriver" 
            serviceType="Kalibrering" 
            serviceNumber="4932" 
            interval="1 år" 
            startDate="23. Januar 2022" 
            endDate="23 Januar 2023"
            [expandable]="true">
          </service-tracker>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>icon</b> -
                Define a Font Awesome icon using 'Icon Name' to be used for the title icon
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>serviceType</b> -
                Describes the type of service
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>serviceNumber</b> -
                Defines the unique number assigned to the specific service
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>interval</b> -
                Describes the length of interval the service has assigned
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>startDate</b> -
                Defines a start date for the service progress bar
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>endDate</b> -
                Defines an end date for the service progress bar
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="pieCharts">
          <span class="content-section-subtitle">Pie Charts</span>
          <p class="content-section-tag">&lt;chart-ring&gt;</p>

          <chart-ring style="width: 350px; height: 350px;" [indicator]="50" text="Informational text in the center of chart">
            <chart-ring-part color="red" [weight]="33" text="Red part"></chart-ring-part>
            <chart-ring-part color="blue" [weight]="33" text="Blue part"></chart-ring-part>
            <chart-ring-part color="yellow" [weight]="33" text="Yellow part"></chart-ring-part>
          </chart-ring>

          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>thickness</b> -
                Controls the thickness of the arch of the chart.
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Text shown in the center of the char.
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>indicator</b> -
                Shows an indicator arrow pointing at the given weight.
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>content</b> -
                List of &lt;chart-ring-part&gt;, each representing a part of the chart. 
              </p>
            </div>
          </div>
          <p class="content-section-tag">&lt;chart-ring-part&gt;</p>

          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>weight</b> -
                The weight of this part. Used to calculate the proportinal size in the resulting diagram.
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Text to be shown when hovering over this part.
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>color</b> -
                The color of the part.
              </p>
            </div>
          </div>
        </div>

      </div>

    </div>

    <!-- 9. VARIOUS COMPONENTS -->
    <div class="content-container" id="components">
      <div class="content-title">
        <fa-icon icon="object-group"></fa-icon>
        <span class="content-title-text">Various Components</span>
      </div>

      <div class="content-section-container">

        <div class="content-section" id="attachments">
          <span class="content-section-subtitle">Attachments</span>
          <p class="content-section-tag">&lt;attachments&gt;</p>
          <attachments 
            title="Title"
            style="width: 600px;"
            [attachments]="[
              { url: '1', filename: 'certificate.pdf', displayName: 'Certificate' },
              { url: '2', filename: 'stuff.pdf', displayName: 'Stuff' },
              { url: '3', filename: 'dis.pdf', displayName: 'Dis' },
              { url: '4', filename: 'dat.pdf', displayName: 'Dat' },
          ]">
          </attachments>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Sets the title of the attachments box.
              </p>
              <p class="content-section-attr-item-text">
                <b>[attachments]</b> -
                List of attachments to display in the box.
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="categoryChips">
          <span class="content-section-subtitle">Category Chips</span>
          <p class="content-section-tag">&lt;category-chip&gt;</p>
          <p class="content-section-text">Used to display an equipment <b>category</b></p>
          <category-chip name="Boremaskine"></category-chip>
        </div>

        <div class="content-section" id="checkBox">
          <span class="content-section-subtitle">Checkbox w/ Text</span>
          <p class="content-section-tag">&lt;checkbox-text&gt;</p>
          <checkbox-text text="Checkbox Text Example"></checkbox-text>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Inserts a string of text to the right of the checkbox
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="commentFields">
          <span class="content-section-subtitle">Comment Fields</span>
          <p class="content-section-tag">&lt;comment-field&gt;</p>
          <p class="content-section-text">A responsive comment field that auto-scales based on parent object</p>
          <comment-field></comment-field>
        </div>

        <div class="content-section" id="counter">
          <span class="content-section-subtitle">Counters</span>
          <p class="content-section-tag">&lt;counter&gt;</p>
          <p class="content-section-text">Functions as a counter to use for displaying notification or attachment amounts etc.</p>
          <counter></counter>
        </div>

        <div class="content-section" id="imageGallery">
          <span class="content-section-subtitle">Image Gallery</span>
          <p class="content-section-tag">&lt;image-gallery&gt;</p>
          <p class="content-section-text">Displays a slideshow of images</p>
          <image-gallery [images]="images"></image-gallery>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>[type]</b> -
                Changes between different size presets for the gallery. Inserted as <b>GallerySize.</b> followed by a size; Large, Medium, Small or Mini. Default is <b>GallerySize.Large</b>
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="serviceLog">
          <span class="content-section-subtitle">Service Log</span>
          <p class="content-section-tag">&lt;log&gt;</p>
          <p class="content-section-text">A 'sidepanel' element that displays a list of service entries, that can be expanded individually to show additional information about an entry. This element contains pagination</p>
          <log title="Servicelog">
            <log-item title="Kalibrering" date="22/07 - 2022" text="Kalibreret af Dwight Schrute"></log-item>
            <log-item icon="hammer" title="Kalibrering" date="22/07 - 2022" text="Kalibreret af Dwight Schrute"></log-item>
            <log-item title="Kalibrering" date="22/07 - 2022" text="Kalibreret af Dwight Schrute"></log-item>
          </log>
          <div class="content-section-attr">Attributes
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>title</b> -
                Describes the type of service that has been logged, in string format OR to be used on the &lt;log&gt; element to change the title of the entire element
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>date</b> -
                Defines a date attached to the individual log entry
              </p>
            </div>
            <div class="content-section-attr-item">
              <p class="content-section-attr-item-text">
                <b>text</b> -
                Describes the action on the individual log entry, and by which employee it was done
              </p>
            </div>
          </div>
        </div>

        <div class="content-section" id="searchBar">
          <span class="content-section-subtitle">Search Bar</span>
          <p class="content-section-tag">&lt;search-bar&gt;</p>
          <p class="content-section-text">A responsive searchbar that can either be loosely placed on a main page or within a parent object</p>
          <search-bar></search-bar>
        </div>

      </div>

    </div>

  </div>

  <!-- <div class="scroll-container">
    <a href="/library#top"><fa-icon icon="arrow-up"></fa-icon></a>
  </div> -->
</div>