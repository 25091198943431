import { NgModule } from "@angular/core";
import { ServiceTrackerComponent } from "./serviceTracker.component";
import { FontAwesomeIconsModule } from "src/font-awesome-icons.module";
import { ComponentsModule } from "src/components/components.module";
import { EmployeesModule } from "src/employees/employees.module";
import { PaginatorModule } from "src/pagination/paginator.module";
import { AttachmentsModule } from "src/attachments/attachments.module";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { ButtonsModule } from "src/components/buttons/buttons.module";
import { InfoBoxModule } from "src/components/infoboxes/infoBox.module";

@NgModule({
    declarations: [
        ServiceTrackerComponent
    ],
    exports: [
        ServiceTrackerComponent
    ],
    imports: [
        FontAwesomeIconsModule,
        ComponentsModule,
        EmployeesModule,
        PaginatorModule,
        AttachmentsModule,
        NgIf,
        NgTemplateOutlet,
        ButtonsModule,
        InfoBoxModule,
    ]
})
export class ServicesModule { }