import { AfterViewInit, Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DropdownOption } from "src/components/dropdown-option";

export enum EntityType {
    Employee,
    Equipment
}

@Component({
    selector: 'search-header',
    templateUrl: 'searchHeader.component.html',
    styleUrls: ['searchHeader.component.scss']
})
export class SearchHeaderComponent implements AfterViewInit {
    @Input()
    selectedEntity: EntityType = EntityType.Equipment;

    @Output()
    selectedEntityChange = new EventEmitter<EntityType>();

    @Input()
    activityOptions: DropdownOption[] = [];

    @Input()
    sortingOptions: DropdownOption[] = [];

    @Output()
    searched = new EventEmitter<string>();

    @Input()
    selectedActivity: any;

    @Output()
    selectedActivityChange = new EventEmitter<any>();

    @Input()
    selectedSorting: any;

    @Output()
    selectedSortingChange = new EventEmitter<any>();

    isInitialized = false;

    get EntityType(): typeof EntityType {
        return EntityType;
    }

    constructor(
        private router: Router
    ) { }

    ngAfterViewInit(): void {
        this.isInitialized = true;
    }

    entitySelected(value: any) {
        if(!this.isInitialized)
            return;

        switch(value) {
            case EntityType.Employee:
                this.router.navigate(['employees']);
            break;

            case EntityType.Equipment:
                this.router.navigate(['equipment']);
            break;

            default:
                throw Error("Not implemented!");
        }
    }
}