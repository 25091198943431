<div class="integrations">
    <div class="left">
        <div class="integrations-topbar">
            <span class="integrations-topbar-title" localize="integrations"></span>
            <fa-icon icon="puzzle-piece"></fa-icon>
        </div>
        <div class="integrations-list">
            <keatech-integration *ngIf="!keatech.isActivated" [state]="keatech"></keatech-integration>
            <xena-integration *ngIf="!xena.isActivated" [state]="xena"></xena-integration>
            <integration title="EG Construction Site" text="Integration med EG Construction Site" imageUrl="https://global.eg.dk/siteassets/media/newspressreleases-1000x500/2021/eg-easyupdate.jpg"></integration>
        </div>
    </div>
    <div class="integrations-active">
        <span class="integrations-active-title" localize="activeIntegrations"></span>
        <div class="integrations-active-list" #activeList>
            <keatech-integration *ngIf="keatech.isActivated" [state]="keatech"></keatech-integration>
            <xena-integration *ngIf="xena.isActivated" [state]="xena"></xena-integration>
        </div>
    </div>
</div>