<div class="container">
    <span class="container-title">Billeder</span>
    <div class="top-container">
        <image-gallery [images]="images"></image-gallery>
        <div class="top-container-icons">
            <icon-text-button icon="rotate-right" text="Rotér" shape="square" (click)="rotate()"></icon-text-button>
            <icon-text-button icon="star" text="Favorit" shape="square" (click)="favorite()"></icon-text-button>
            <icon-text-button icon="trash-can" text="Slet" shape="square" color="red" (click)="delete()"></icon-text-button>
        </div>
    </div>
    <div class="bottom-container" id="drag_drop" (drop)="dropHandler($event)" (dragover)="dragOverHandler($event)">
        <span class="bottom-container-title">Træk billede hertil for at tilføje</span>
        <span class="bottom-container-subtitle">.png, .img, .jpg, .jpeg</span>
        <icon-text-button icon="image" text="Billeder" shape="square" (click)="add()"></icon-text-button>
    </div>
</div>